import React, { useEffect, useState, useRef } from "react";
import ItemsHeader from "../AllItems/NewOffersHeader";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ProductCard from "../../product/ProductCard";
import { set_all_items } from "../../../redux/actions/AllItemsAction";
import { connect } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";
import ProductCardMobile from "../../mobile/Home/ProductCardMobile";
import "../../../assets/styles/component/customer/AllItems/ItemsMain.scss";
import {
  getDesktopImage,
  getMobileImage,
  replaceAt,
} from "../../../utils/utils";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import CartSidebar from "../cart/CartSidebar";
import { useOutsideAlerter } from "../../../utils/CustomHooks";
import NotifyUser from "../../product/NotifyUser";

const NewOffersSectionItemsMain = (props) => {
  const { t } = useTranslation();
  const [forceRender, setForceRender] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const history = useHistory();
  const location = useLocation();
  let type = "products";
  const params = useParams();
  const sideCartRef = useRef(null);
  const [showCart, setShowCart] = useState(false);
  const [showNotifyModel, setShowNotifyModel] = useState(false);
  const [orderableId, setOrderableId] = useState(null);

  useOutsideAlerter(sideCartRef, setShowCart);

  const setShowCartSidebar = (val) => {
    setShowCart(val);
  }
  // type = type.split("?")[0];

  let message = "";

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getFiltersString = (
    page,
    records,
    price_high_to_low,
    price_low_to_high,
    new_arrivals,
    selectedCat,
    selectedBrands,
    minPrice,
    maxPrice,
    selectedStores,
    rating
  ) => {
    let categories = "";
    selectedCat &&
      selectedCat.length > 0 &&
      selectedCat.map(
        (cat) => (categories = categories + "&filter_by_category[]=" + cat)
      );

    let brands = "";
    selectedBrands &&
      selectedBrands.length > 0 &&
      selectedBrands.map(
        (brandId) => (brands = brands + "&filter_by_brand[]=" + brandId)
      );
    let stores = "";
    selectedStores &&
      selectedStores.length > 0 &&
      selectedStores.map(
        (storeId) => (stores = stores + "&filter_by_store[]=" + storeId)
      );

    return (
      (parseInt(page) !== 1 ? "&page=" + page : "") +
      (parseInt(records) !== 30 ? "&records_per_page=" + records : "") +
      (price_high_to_low ? "&price_high_to_low=true" : "") +
      (price_low_to_high ? "&price_low_to_high=true" : "") +
      (new_arrivals ? "&order_by_new_arrivals=" + new_arrivals : "") +
      (selectedCat && selectedCat.length > 0 ? categories : "") +
      (selectedBrands && selectedBrands.length > 0 ? brands : "") +
      (minPrice > 0
        ? "&filter_by_price[min]=" + (minPrice ? minPrice : 0)
        : "") +
      (parseFloat(maxPrice) !== 9999999
        ? "&filter_by_price[max]=" + (maxPrice ? maxPrice : 9999999)
        : "") +
      (selectedStores && selectedStores.length > 0 ? stores : "") +
      (rating > 0 ? "&filter_by_rating=" + (rating ? rating : 0) : "")
    );
  };

  const applyFilters = (
    selectedCat,
    selectedBrands,
    minPrice,
    maxPrice,
    selectedStores,
    rating
  ) => {
    let filters = getFiltersString(
      1,
      props.recordPerPage,
      props.price_high_to_low,
      props.price_low_to_high,
      props.new_arrivals,
      selectedCat,
      selectedBrands,
      minPrice,
      maxPrice,
      selectedStores,
      rating
    );
    filters = encodeURI(filters);
    history.push(
      location.state
        ? {
          pathname: window.location.pathname,
          search: replaceAt(filters, 0, "?"),
          state: location.state,
        }
        : {
          pathname: window.location.pathname,
          search: replaceAt(filters, 0, "?"),
        }
    );
    // props.set_loading(true);
    // window.scroll(0, 0);

    // apiJson.offers
    //   .offers_section_variants(
    //     props.countryId,
    //     params.id,
    //     1,
    //     props.recordPerPage,
    //     props.price_high_to_low,
    //     props.price_low_to_high,
    //     props.new_arrivals,
    //     selectedCat,
    //     selectedBrands,
    //     minPrice,
    //     maxPrice,
    //     selectedStores,
    //     rating
    //   )
    //   .then((response) => {
    //     if (response.success) {
    //       props.set_all_items({
    //         items: response.data.variants,
    //         pagination: response.data.pagination,
    //       });
    //       props.set_loading(false);
    //     } else if (!response.status) {
    //       props.set_loading(false);
    //       // eslint-disable-next-line
    //       message = response.message
    //         ? response.message !== ""
    //           ? response.message
    //           : t("errors.wrong")
    //         : t("errors.wrong");
    //       showError();
    //     } else {
    //       props.set_loading(false);
    //       if (response.status >= 400 && response.status < 500) {
    //         message = response.message
    //           ? response.message
    //           : response.errors
    //             ? response.errors
    //             : t("errors.wrong");
    //         showError();
    //       } else {
    //         message = response.message
    //           ? response.message !== ""
    //             ? response.message
    //             : t("errors.wrong")
    //           : t("errors.wrong");
    //         showError();
    //       }
    //     }
    //   });
  };

  const handlePageChange = (pageNumber) => {
    let filters = getFiltersString(
      pageNumber.selected + 1,
      props.recordPerPage,
      props.price_high_to_low,
      props.price_low_to_high,
      props.new_arrivals,
      props.selectedCat === "" ? [] : props.selectedCat,
      props.selectedBrands,
      props.minPrice,
      props.maxPrice,
      props.selectedStores,
      props.rating
    );
    filters = encodeURI(filters);
    history.push(
      location.state
        ? {
          pathname: window.location.pathname,
          search: replaceAt(filters, 0, "?"),
          state: location.state,
        }
        : {
          pathname: window.location.pathname,
          search: replaceAt(filters, 0, "?"),
        }
    );
    window.scroll(0, 0);
    props.set_loading(true);

    apiJson.newoffers
      .offers_section_variants(
        props.countryId,
        params.id,
        pageNumber.selected + 1,
        props.recordPerPage,
        props.price_high_to_low,
        props.price_low_to_high,
        props.new_arrivals,
        props.selectedCat === "" ? [] : props.selectedCat,
        props.selectedBrands,
        props.minPrice,
        props.maxPrice,
        props.selectedStores,
        props.rating
      )
      .then((response) => {
        if (response.success) {
          props.set_all_items({
            page: pageNumber.selected + 1,
            items:
              props.type === "products"
                ? response.data.variants
                : response.data.services,
            pagination: response.data.pagination,
          });
          props.set_loading(false);
        } else if (!response.status) {
          props.set_loading(false);
          // eslint-disable-next-line
          message = response.message
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          props.set_loading(false);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  useEffect(() => {
    // if (props.data && firstRender) {
    //   let filters = getFiltersString(
    //     props.page,
    //     props.recordPerPage,
    //     props.price_high_to_low,
    //     props.price_low_to_high,
    //     props.new_arrivals,
    //     props.selectedCat === "" ? [] : props.selectedCat,
    //     props.selectedBrands,
    //     props.minPrice,
    //     props.maxPrice,
    //     props.selectedStores,
    //     props.rating
    //   );
    //   if (filters.length > 0) {
    //     filters = encodeURI(filters);
    //     history.push(
    //       location.state
    //         ? {
    //           pathname: window.location.pathname,
    //           search: replaceAt(filters, 0, "?"),
    //           state: location.state,
    //         }
    //         : {
    //           pathname: window.location.pathname,
    //           search: replaceAt(filters, 0, "?"),
    //         }
    //     );
    //   }
    // }
    if (props.data) {
      if (firstRender) {
        setFirstRender(false);

        return;
      }
      if (props.width !== "sm") {
        if (localStorage.getItem("load_items") == "true") {
          applyFilters(
            props.selectedCat === "" ? [] : props.selectedCat,
            props.selectedBrands,
            props.minPrice,
            props.maxPrice,
            props.selectedStores,
            props.rating
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [
    props.recordPerPage,
    props.price_low_to_high,
    props.price_high_to_low,
    props.new_arrivals,
    props.selectedCat,
    props.selectedBrands,
    props.go,
    props.selectedStores,
    props.rating,
  ]);

  useEffect(() => {
    setFirstRender(true);
  }, [location.state?.brand, type]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setForceRender(!forceRender);
  }, [props.sortVal]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="item-main-desktop">
      <div className="w-100">
        <ItemsHeader
          type={props.type}
          sortBy={props[`sortBy_${props.language}`]}
          display={props[`display_${props.language}`]}
          filterBy={props.filter_by}
          RemoveFilter={(val) => {
            props.set_all_items({
              brand: props.brand.map((ele) => ({
                ...ele,
                name_en: ele.name_en,
                name_ar: ele.name_ar,
                count: ele.count,
                checked: false,
              })),
              seller: props.seller.map((ele) => ({
                ...ele,
                name_en: ele.name_en,
                name_ar: ele.name_ar,
                count: ele.count,
                checked: false,
              })),
              filter_by: val,
              mobileFiltersCache: val,
              minPrice: "0",
              maxPrice: "9999999",
              rating: 0,
              selectedCat: "",
              selectedBrands: [],
              selectedStores: [],
              go: !props.go,
            });
            props.width === "sm" && applyFilters([], [], "0", "9999999", [], 0);
          }}
          removedEle={(val, filters) => {
            if (val.heading === t("itemsSidebar.brand")) {
              const id = props.brand.filter(
                (ele) =>
                  ele[`name_${props.language}`].toLowerCase() ===
                  val.name.toLowerCase()
              )[0].id;
              const filteredBrands = props.selectedBrands.filter(
                (brand) => brand !== id
              );
              props.set_all_items({
                filter_by: filters,
                mobileFiltersCache: filters,
                selectedBrands: filteredBrands,
                brand: props.brand.map((ele, i) =>
                  ele[`name_${props.language}`].toLowerCase() !==
                    val.name.toLowerCase()
                    ? ele
                    : {
                      ...ele,
                      name_en: ele.name_en,
                      name_ar: ele.name_ar,

                      count: ele.count,
                      checked: !ele.checked,
                    }
                ),
                seller: props.seller,
              });

              props.width === "sm" &&
                applyFilters(
                  props.selectedCat === "" ? [] : props.selectedCat,
                  filteredBrands,
                  props.minPrice,
                  props.maxPrice,
                  props.selectedStores,
                  props.rating
                );
            } else if (val.heading === t("itemsSidebar.price")) {
              props.set_all_items({
                go: !props.go,
                brand: props.brand,
                seller: props.seller,
                minPrice: "0",
                maxPrice: "9999999",
                filter_by: props.filter_by.filter(
                  (val) => val.heading !== t("itemsSidebar.price")
                ),
                mobileFiltersCache: props.filter_by.filter(
                  (val) => val.heading !== t("itemsSidebar.price")
                ),
              });

              props.width === "sm" &&
                applyFilters(
                  props.selectedCat === "" ? [] : props.selectedCat,
                  props.selectedBrands,
                  "0",
                  "9999999",
                  props.selectedStores,
                  props.rating
                );
            } else if (val.heading === t("itemsSidebar.rating")) {
              props.set_all_items({
                brand: props.brand,
                seller: props.seller,
                rating: 0,
                filter_by: props.filter_by.filter(
                  (val) => val.heading !== t("itemsSidebar.rating")
                ),
                mobileFiltersCache: props.filter_by.filter(
                  (val) => val.heading !== t("itemsSidebar.rating")
                ),
              });

              props.width === "sm" &&
                applyFilters(
                  props.selectedCat === "" ? [] : props.selectedCat,
                  props.selectedBrands,
                  props.minPrice,
                  props.maxPrice,
                  props.selectedStores,
                  0
                );
            } else {
              const id = props.seller.filter(
                (ele) =>
                  ele[`name_${props.language}`].toLowerCase() ===
                  val.name.toLowerCase()
              )[0].id;
              const filteredStores = props.selectedStores.filter(
                (seller) => seller !== id
              );
              props.set_all_items({
                filter_by: filters,
                mobileFiltersCache: filters,
                selectedStores: filteredStores,
                seller: props.seller.map((ele, i) =>
                  ele[`name_${props.language}`].toLowerCase() !==
                    val.name.toLowerCase()
                    ? ele
                    : {
                      ...ele,
                      name_en: ele.name_en,
                      name_ar: ele.name_ar,

                      count: ele.count,
                      checked: !ele.checked,
                    }
                ),
                brand: props.brand,
              });

              props.width === "sm" &&
                applyFilters(
                  props.selectedCat === "" ? [] : props.selectedCat,
                  props.selectedBrands,
                  props.minPrice,
                  props.maxPrice,
                  filteredStores,
                  props.rating
                );
            }
          }}
          title={props.title}
          showBrands={false}
        />
      </div>

      {props.data && (
        <div className={props.language == "en" ? "items-body row no-gutters" : "items-body items-body-ar row no-gutters"}>
          {props.items?.map((value) => (
            <React.Fragment key={value.id}>
              {
                props.width == "sm" ?
                  <div className="large-display-none">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname:
                          "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          `/${value.category_title_en
                            .toLowerCase()
                            .replace(/ /g, "-")}/${value.product_slug}`,
                        state: {
                          id: value.id,
                          type: "products",
                          sku: value.sku
                        },
                        search: "?sku=" + value.sku
                      }}
                    >
                      <div>
                        <ProductCardMobile
                          key={value.id + "prod"}
                          setShowNotifyModel={setShowNotifyModel}
                          setOrderableId={setOrderableId}
                          // img={getMobileImage(
                          //   value.variants[0].featured_image_resized,
                          //   value.variants[0].featured_image
                          // )}
                          img={value.featured_image}
                          wishlisted={value.wishlisted}
                          type="products"
                          storeLogo={value.store_logo}
                          id={value.id}
                          name={value[`product_name_${props.language}`] + " " + value[`name_${props.language}`]}
                          data={value}
                          rating={value.average_rating}
                          reviews={value.reviews_count}
                          coupon={value.coupon}
                          is_inquiry={value.is_inquiry}
                          category_en={value.category_title_en}
                          slug={value.product_slug}
                        />
                      </div>
                    </Link>
                  </div>
                  :
                  <ProductCard
                    key={value.id + Math.random() + "prod"}
                    setShowNotifyModel={setShowNotifyModel}
                    setOrderableId={setOrderableId}
                    home={props.home}
                    product={{
                      id: value.product_id,
                      slug: value.product_slug,
                      line_item_id: value.id,
                      img: getDesktopImage(
                        value.featured_image_resized,
                        value.featured_image
                      ),
                      type: "products",
                      description: value.product_name_en + " " + value.name_en,
                      storeLogo: value.store_logo,
                      description_en: value.product_name_en,
                      category_en: value.category_title_en,
                      price_after_discount: parseFloat(
                        value.discounted_price
                      ),
                      price_before_discount: parseFloat(
                        value.price
                      ),
                      rating: parseInt(value.average_rating),
                      count: value.reviews_count,
                      wishlisted: value.wishlisted,
                      sku: value.sku,
                      coupon: value.coupon,
                      is_inquiry: value.is_inquiry,
                      quantity: value.quantity
                    }}
                    onQuickViewClicked={props.onQuickViewClicked}
                    setShowCartSidebar={setShowCartSidebar}
                  />
              }
            </React.Fragment>
          ))}
        </div>
      )}
      <div
        className="d-flex justify-content-center mb-4"
        style={{ direction: "ltr", maxWidth: "98%" }}
      >
        {props?.pagination?.total_pages > 1 && props.data && (
          <Pagination
            direction="ltr"
            previousLabel={<FaChevronLeft color="#404553" />}
            nextLabel={<FaChevronRight color="#404553" />}
            pageCount={parseInt(props?.pagination?.total_pages)}
            forcePage={parseInt(props?.pagination?.page) - 1}
            onPageChange={handlePageChange}
            pageRangeDisplayed={props.width === "sm" ? 1 : 2}
            marginPagesDisplayed={props.width === "sm" ? 1 : 2}
            containerClassName="pagination"
            pageClassName="pl-3 pr-3 pt-2 pb-2"
            activeClassName={
              "ml-3 mr-3 text-primary border border-primary font-weight-bold rounded bg-white"
            }
            nextClassName="bg-white pl-3 pr-3 pt-2 pb-2"
            previousClassName="bg-white pl-3 pr-3 pt-2 pb-2"
          />
        )}
        {props?.pagination?.total_pages < 2 &&
          props.data &&
          props?.items?.length > 0 && (
            <div
              className="ml-3 mr-3 text-primary border border-primary font-weight-bold rounded bg-white pl-3 pr-3 pt-2 pb-2
  "
            >
              <span>1</span>
            </div>
          )}
      </div>
      {showCart && <CartSidebar refs={sideCartRef} bool={showCart} />}
      <NotifyUser
        setShowNotifyModel={setShowNotifyModel}
        showNotifyModel={showNotifyModel}
        id={orderableId}
        language={props.language}
        isSmall={true}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    seller: state.allItems.seller,
    recordPerPage: state.allItems.recordPerPage,
    page: state.allItems.page,
    rating: state.allItems.rating,
    minPrice: state.allItems.minPrice,
    price_high_to_low: state.allItems.price_high_to_low,
    price_low_to_high: state.allItems.price_low_to_high,
    selectedBrands: state.allItems.selectedBrands,
    selectedStores: state.allItems.selectedStores,
    new_arrivals: state.allItems.new_arrivals,
    go: state.allItems.go,
    maxPrice: state.allItems.maxPrice,
    brand: state.allItems.brand,
    filter_by: state.allItems.filter_by,
    sortBy_en: state.allItems.sortBy_en,
    display_en: state.allItems.display_en,
    sortBy_ar: state.allItems.sortBy_ar,
    display_ar: state.allItems.display_ar,
    items: state.allItems.items,
    sortVal: state.allItems.sortVal,
    width: state.layout.screenWidth,
    selectedCat: state.allItems.selectedCat,
    originalItems: state.allItems.originalItems,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    load: state.layout.loading,
    countryId: state.language.countryId,
    pagination: state.allItems.pagination,
  };
};

const mapDispatchToProps = {
  set_loading,
  set_all_items,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewOffersSectionItemsMain);
