import axios from "axios";
import { apiUrl } from "./constants";

const responseThen = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return { success: true, data: response.data };
  } else {
    return {
      success: false,
      status: response.status,
      message: response.data ? response.data.message : response.message,
    };
  }
};

const responseCatch = (error) => {
  if (error.response) {
    return {
      success: false,
      status: error.response?.status,
      message: error.response.data.message
        ? error.response.data.message
        : error.response.statusText,
    };
  } else {
    if (error.message && error.message == "Network Error") {
      return {
        success: false,
        status: 503,
        message: lang() === "ar" ? "هناك خطأ ما" : "something went wrong",
      };
    }
    else {
      return {
        success: false,
        status: 500,
        message: lang() === "ar" ? "هناك خطأ ما" : "something went wrong",
      };
    }
  }
};

const userKey = () =>
  localStorage.getItem("user_key") ? localStorage.getItem("user_key") : "";
const lang = () =>
  localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "";

export const apiJson = {
  homepage: {
    getHomeTabs: function getHomeTabs(id) {
      return axios
        .get(apiUrl.homepage.home_tabs + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getHomeOffersSections: function getHomeOffersSections(id) {
      return axios
        .get(apiUrl.homepage.home_offers_sections + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getBanners: function getBanners(id) {
      return axios
        .get(apiUrl.homepage.banners + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    offer: function offers() {
      return axios
        .get(apiUrl.homepage.offers)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getData: function getData(id) {
      return axios
        .get(apiUrl.homepage.data + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getOffersData: function offersData(id, page, records_per_page) {
      return axios
        .get(apiUrl.homepage.offers_data + id + "&page=" + page + "&records_per_page=" + records_per_page + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getCategories: function getCategories(id) {
      return axios
        .get(apiUrl.homepage.categories + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getSections: function getSections(id, c_id) {
      return axios
        .get(
          apiUrl.homepage.sections +
          id +
          "?country_id=" +
          c_id +
          "&lang=" +
          lang(),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            }
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getFirstSection: function getFirstSection(c_id) {
      return axios
        .get(
          apiUrl.homepage.firstSection +
          "?country_id=" +
          c_id +
          "&lang=" +
          lang(),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            }
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getNewArrivals: function getNewArrivals(id) {
      return axios
        .get(
          encodeURI(
            apiUrl.homepage.newArrivals +
            id +
            "&section=New Arrivals&lang=" +
            lang()
          ),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getBestSellers: function getBestSellers(id) {
      return axios
        .get(
          encodeURI(
            apiUrl.homepage.newArrivals +
            id +
            "&section=Best Seller&lang=" +
            lang()
          ),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getRecommended: function getRecommended(id) {
      return axios
        .get(
          encodeURI(
            apiUrl.homepage.recommended_for_you +
            id +
            "&section=Recommended For You&lang=" +
            lang()
          ),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getDiyProducts: function getDiyProducts(id) {
      return axios
        .get(
          encodeURI(
            apiUrl.homepage.diyProducts +
            id +
            "&section=DIY Products" +
            "&lang=" +
            lang()
          ),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getPaintersTouch: function getPaintersTouch(id) {
      return axios
        .get(
          encodeURI(
            apiUrl.homepage.paintersTouch +
            id +
            "&section=Painters Touch" +
            "&lang=" +
            lang()
          ),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    getKrudKutter: function getKrudKutter(id) {
      return axios
        .get(
          encodeURI(
            apiUrl.homepage.krudKutter +
            id +
            "&section=Krud Kutter" +
            "&lang=" +
            lang()
          ),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  sections: {
    getSprayPaintsSection: function getSprayPaintsSection(c_id) {
      return axios
        .get(apiUrl.sections.getSprayPaintsSection + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getCamping1Section: function getCamping1Section(c_id) {
      return axios
        .get(apiUrl.sections.getCamping1Section + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getCamping2Section: function getCamping2Section(c_id) {
      return axios
        .get(apiUrl.sections.getCamping2Section + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getDubaiEvents1Section: function getDubaiEvents1Section(c_id) {
      return axios
        .get(apiUrl.sections.getDubaiEvents1Section + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getDubaiEvents2Section: function getDubaiEvents2Section(c_id) {
      return axios
        .get(apiUrl.sections.getDubaiEvents2Section + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getDubaiEvents3Section: function getDubaiEvents3Section(c_id) {
      return axios
        .get(apiUrl.sections.getDubaiEvents3Section + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getDubaiEvents4Section: function getDubaiEvents4Section(c_id) {
      return axios
        .get(apiUrl.sections.getDubaiEvents4Section + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getRamadanEssentialsSection: function getRamadanEssentialsSection(c_id) {
      return axios
        .get(apiUrl.sections.getRamadanEssentialsSection + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getSectionProducts: function getSectionProducts(section_id, c_id, page, records_per_page) {
      return axios
        .get(apiUrl.sections.getSectionProducts + section_id + "/section_products?country_id=" + c_id + "&page=" + page + "&records_per_page=" + records_per_page)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  offers: {
    offers_sections: function getOffersSections(c_id) {
      return axios
        .get(apiUrl.offers.offers_sections + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    offers_section_variants: function getOffersSections(c_id, s_id, page, records_per_page, price_high_to_low, price_low_to_high, new_arrivals, selectedCat, selectedBrands, minPrice, maxPrice, selectedStores, rating) {
      let categories = "";
      selectedCat &&
        selectedCat.length > 0 &&
        selectedCat.map(
          (cat) => (categories = categories + "&category_ids[]=" + cat)
        );

      let brands = "";
      selectedBrands &&
        selectedBrands.length > 0 &&
        selectedBrands.map(
          (brandId) => (brands = brands + "&brand_ids[]=" + brandId)
        );
      let stores = "";
      selectedStores &&
        selectedStores.length > 0 &&
        selectedStores.map(
          (storeId) => (stores = stores + "&store_ids[]=" + storeId)
        );
      return axios
        .get(
          apiUrl.offers.offers_section_variants +
          "?country_id=" + c_id +
          "&section_id=" + s_id +
          "&page=" + page +
          "&records_per_page=" + records_per_page +
          (price_high_to_low ? "&price_high_to_low=true" : "") +
          (price_low_to_high ? "&price_low_to_high=true" : "") +
          (selectedCat && selectedCat.length > 0 ? categories : "") +
          (selectedBrands && selectedBrands.length > 0 ? brands : "") +
          "&filter_by_price[min]=" +
          (minPrice != "" ? minPrice : 0) +
          "&filter_by_price[max]=" +
          (maxPrice ? maxPrice : 9999999) +
          (selectedStores && selectedStores.length > 0 ? stores : "") +
          "&filter_by_rating=" +
          (rating ? rating : 0)
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getTopBanner: function getTopBanner(c_id) {
      return axios
        .get(apiUrl.offers.top_banner + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getBoxesBanners: function getBoxesBanners(c_id) {
      return axios
        .get(apiUrl.offers.boxes_banner + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    }
  },

  newoffers: {
    offers_sections: function getOffersSections(c_id) {
      return axios
        .get(apiUrl.newoffers.offers_sections + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    offers_section_variants: function getOffersSections(c_id, s_id, page, records_per_page, price_high_to_low, price_low_to_high, new_arrivals, selectedCat, selectedBrands, minPrice, maxPrice, selectedStores, rating) {
      let categories = "";
      selectedCat &&
        selectedCat.length > 0 &&
        selectedCat.map(
          (cat) => (categories = categories + "&category_ids[]=" + cat)
        );

      let brands = "";
      selectedBrands &&
        selectedBrands.length > 0 &&
        selectedBrands.map(
          (brandId) => (brands = brands + "&brand_ids[]=" + brandId)
        );
      let stores = "";
      selectedStores &&
        selectedStores.length > 0 &&
        selectedStores.map(
          (storeId) => (stores = stores + "&store_ids[]=" + storeId)
        );
      return axios
        .get(
          apiUrl.newoffers.offers_section_variants +
          "?country_id=" + c_id +
          "&section_id=" + s_id +
          "&page=" + page +
          "&records_per_page=" + records_per_page +
          (price_high_to_low ? "&price_high_to_low=true" : "") +
          (price_low_to_high ? "&price_low_to_high=true" : "") +
          (selectedCat && selectedCat.length > 0 ? categories : "") +
          (selectedBrands && selectedBrands.length > 0 ? brands : "") +
          "&filter_by_price[min]=" +
          (minPrice != "" ? minPrice : 0) +
          "&filter_by_price[max]=" +
          (maxPrice ? maxPrice : 9999999) +
          (selectedStores && selectedStores.length > 0 ? stores : "") +
          "&filter_by_rating=" +
          (rating ? rating : 0)
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getTopBanner: function getTopBanner(c_id) {
      return axios
        .get(apiUrl.newoffers.top_banner + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getBoxesBanners: function getBoxesBanners(c_id) {
      return axios
        .get(apiUrl.newoffers.boxes_banner + "?country_id=" + c_id)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    }
  },

  singleItem: {
    getItemData: function getItemData(id, type, c_id) {
      return axios
        .get(
          apiUrl.singleItem.getItemData +
          type +
          "/" +
          id +
          "?country_id=" +
          c_id +
          "&lang=" +
          lang(),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getFreqData: function getFreqData(sku) {
      return axios
        .get(
          apiUrl.singleItem.getFreqData +
         "?sku="+sku,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  
  vendorShop: {
    getMiddleBanners: function getMiddleBanners(store_id) {
      return axios
        .get(
          apiUrl.vendorShop.getMiddleBanners + store_id,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getMenu: function getMenu(store_id) {
      return axios
        .get(
          apiUrl.vendorShop.getMenu + store_id,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getData: function getData(c_id, v_id) {
      return axios
        .get(
          apiUrl.vendorShop.getData +
          v_id +
          "?country_id=" +
          c_id +
          "&lang=" +
          lang(),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  allitems: {
    getItems: function getItems(
      id,
      type,
      page,
      records,
      price_high_to_low,
      price_low_to_high,
      new_arrivals,
      selectedCat,
      selectedBrands,
      minPrice,
      maxPrice,
      selectedStores,
      rating
    ) {
      let categories = "";
      selectedCat &&
        selectedCat.length > 0 &&
        selectedCat.map(
          (cat) => (categories = categories + "&filter_by_category[]=" + cat)
        );

      let brands = "";
      selectedBrands &&
        selectedBrands.length > 0 &&
        selectedBrands.map(
          (brandId) => (brands = brands + "&filter_by_brand[]=" + brandId)
        );
      let stores = "";
      selectedStores &&
        selectedStores.length > 0 &&
        selectedStores.map(
          (storeId) => (stores = stores + "&filter_by_store[]=" + storeId)
        );
      return axios
        .get(
          apiUrl.allItems.getItems +
          type +
          "?country_id=" +
          id +
          "&lang=" +
          lang() +
          "&page=" +
          page +
          "&records_per_page=" +
          records +
          (price_high_to_low ? "&price_high_to_low=true" : "") +
          (price_low_to_high ? "&price_low_to_high=true" : "") +
          (new_arrivals ? "&order_by_new_arrivals=" + new_arrivals : "") +
          (selectedCat && selectedCat.length > 0 ? categories : "") +
          (selectedBrands && selectedBrands.length > 0 ? brands : "") +
          "&filter_by_price[min]=" +
          (minPrice ? minPrice : 0) +
          "&filter_by_price[max]=" +
          (maxPrice ? maxPrice : 9999999) +
          (selectedStores && selectedStores.length > 0 ? stores : "") +
          "&filter_by_rating=" +
          (rating ? rating : 0),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getCategories: function getCategories(id) {
      return axios
        .get(apiUrl.allItems.getCategories + id + "&lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    getBrands: function getBrands(id) {
      return axios
        .get(apiUrl.header.getBrands + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getSeller: function getSeller(id) {
      return axios
        .get(apiUrl.allItems.getSeller + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    searchItems: function searchItems(
      id,
      type,
      page,
      title,
      records,
      product_type
    ) {
      return axios
        .get(
          apiUrl.allItems.searchItems +
          type + "/search_by_title?country_id=" +
          id +
          "&lang=" +
          lang() +
          "&page=" +
          page +
          "&q=" +
          title +
          "&records_per_page=" +
          records +
          "&product_type=" +
          product_type,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    searchVariants: function searchVariants(
      id,
      type,
      page,
      title,
      records
    ) {
      return axios
        .get(
          apiUrl.allItems.searchItems +
          type + "/search_variants_by_title?country_id=" +
          id +
          "&lang=" +
          lang() +
          "&page=" +
          page +
          "&q=" +
          title +
          "&records_per_page=" +
          records,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getVariants: function getVariants(
      id,
      type,
      page,
      records,
      price_high_to_low,
      price_low_to_high,
      new_arrivals,
      selectedCat,
      selectedBrands,
      minPrice,
      maxPrice,
      selectedStores,
      rating,
      discount_sort,
      product_type
    ) {
      let categories = "";
      selectedCat &&
        selectedCat.length > 0 &&
        selectedCat.map(
          (cat) => (categories = categories + "&filter_by_category[]=" + cat)
        );

      let brands = "";
      selectedBrands &&
        selectedBrands.length > 0 &&
        selectedBrands.map(
          (brandId) => (brands = brands + "&filter_by_brand[]=" + brandId)
        );
      let stores = "";
      selectedStores &&
        selectedStores.length > 0 &&
        selectedStores.map(
          (storeId) => (stores = stores + "&filter_by_store[]=" + storeId)
        );

      return axios
        .get(
          apiUrl.allItems.getVariants +
          // type + 
          "variants" +
          "?country_id=" +
          id +
          "&lang=" +
          lang() +
          "&page=" +
          page +
          "&records_per_page=" +
          records +
          (price_high_to_low ? "&price_high_to_low=true" : "") +
          (discount_sort ? "&discount_sort=true" : "") +
          (price_low_to_high ? "&price_low_to_high=true" : "") +
          (new_arrivals ? "&order_by_new_arrivals=" + new_arrivals : "") +
          (selectedCat && selectedCat.length > 0 ? categories : "") +
          (selectedBrands && selectedBrands.length > 0 ? brands : "") +
          "&filter_by_price[min]=" +
          (minPrice ? minPrice : 0) +
          "&filter_by_price[max]=" +
          (maxPrice ? maxPrice : 9999999) +
          (selectedStores && selectedStores.length > 0 ? stores : "") +
          "&filter_by_rating=" +
          (rating ? rating : 0) +
          "&product_type=" +
          product_type,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  payments: {
    getPaymentData: function getPaymentData() {
      return axios
        .get(apiUrl.payments.getPaymentData + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    deleteCard: function deleteCard(id) {
      return axios
        .delete(apiUrl.payments.deletePayment + "/" + id + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },

    setDefault: function setDefault(id) {
      return axios
        .patch(
          apiUrl.payments.setDefault +
          parseInt(id) +
          "/set_default" +
          "?lang=" +
          lang(),
          {},
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },

  orders: {
    getOrders: function getOrders() {
      return axios
        .get(apiUrl.orders.getOrders + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getBanners: function getBanners(id) {
      return axios
        .get(apiUrl.orders.banners + "?country_id=" + id + "&lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    getOrderDetail: function getOrderDetail(id) {
      return axios
        .get(apiUrl.orders.getOrderDetail + "/" + id + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    cancelOrder: function cancelOrder(id) {
      return axios
        .patch(
          apiUrl.orders.cancelOrder + id + "/cancel?lang=" + lang(),
          {},
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  returnItems: {
    getReturnedItems: function getReturnedItems() {
      return axios
        .get(apiUrl.returnItems.getReturnedItems + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    createRefund: function createRefund(body) {
      return axios
        .post(apiUrl.returnItems.create + "?lang=" + lang(), body, {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  cart: {
    getCartItems: function getCartItems(cid) {
      return axios
        .get(
          apiUrl.cart.getCartItems +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    clearCartItems: function clearCartItems(cid) {
      return axios
        .delete(
          apiUrl.cart.clearCart +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    clearWishlist: function clearWishlist(cid) {
      return axios
        .delete(
          apiUrl.cart.clearWishlist +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    removeItem: function removeItem(id, cid) {
      return axios
        .delete(
          apiUrl.cart.removeItem +
          "/" +
          id +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    updateProduct: function updateProduct(id, body, cid) {
      return axios
        .put(
          apiUrl.cart.updateProduct +
          "/" +
          id +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          body,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    addCoupon: function addCoupon(coupon, cid) {
      return axios
        .get(
          apiUrl.cart.addCoupon +
          coupon +
          "&user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return { success: true, data: response.data };
          } else {
            return {
              success: false,
              status: response.status,
              message: response.message,
            };
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response?.status >= 400 &&
            error.response?.status < 500
          ) {
            return {
              success: false,
              status: error.response?.status,
              message: error.response?.data?.message
                ? error.response?.data.message
                : lang() === "ar"
                  ? "هناك خطأ ما"
                  : "something went wrong",
            };
          } else {
            return {
              success: false,
              status: 500,
              message: lang() === "ar" ? "هناك خطأ ما" : "something went wrong",
            };
          }
        });
    },

    removeCoupon: function removeCoupon(cid) {
      return axios
        .get(
          apiUrl.cart.removeCoupon +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    getWishList: function getWishList(cid) {
      return axios
        .get(
          apiUrl.cart.getWishList +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    addToCart: function addToCart(body, id) {
      return axios
        .post(
          apiUrl.cart.addToCart +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          id,
          body,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    moveToWishlist: function moveToWishlist(body, cid) {
      return axios
        .post(
          apiUrl.cart.moveToWishList +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          body,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    guestUser: function guestUser(id) {
      return axios
        .get(apiUrl.cart.guestUser + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
    getCartBanners: function getCartBanners(id) {
      return axios
        .get(apiUrl.cart.getBanners + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    updateCartReset: function updateCartReset(cid) {
      return axios
        .patch(
          apiUrl.cart.updateCartReset +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {},
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    discountReset: function discountReset(cid) {
      return axios
        .patch(
          apiUrl.cart.discountReset +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {},
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },
  },

  select: {
    getAddress: function getAddress() {
      return axios
        .get(apiUrl.select.getAddress + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    deleteAddress: function deleteAddress(id) {
      return axios
        .delete(apiUrl.select.deletAddress + parseInt(id) + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    updateAddress: function updateAddress(id, body) {
      return axios
        .patch(
          apiUrl.select.updateAddress + parseInt(id) + "?lang=" + lang(),
          body,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    setDefault: function setDefault(id) {
      return axios
        .patch(
          apiUrl.select.setDefault +
          parseInt(id) +
          "/set_default" +
          "?lang=" +
          lang(),
          {},
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    getDefault: function setDefault() {
      return axios
        .get(
          apiUrl.select.getDefault +
          "?lang=" +
          lang(),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    addAdress: function addAddress(body) {
      return axios
        .post(
          apiUrl.select.addAddress +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang(),
          body,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    varifyAdress: function varifyAddress(body, cid) {
      return axios
        .post(
          apiUrl.select.varifyAddress +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          body,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },

  phone: {
    verifyNumber: function verifyNumber(id) {
      return axios
        .get(
          apiUrl.phone.varifyNumber +
          id +
          "/verify_number" +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang(),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    verifyOtp: function verifyOtp(id, otp) {
      return axios
        .get(
          apiUrl.phone.varifyOtp +
          id +
          "/verify_otp?otp=" +
          otp +
          "&user_key=" +
          userKey() +
          "&lang=" +
          lang(),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },

  checkout: {
    pickupAddresses: function pickupAddresses(cid) {
      return axios
        .get(
          apiUrl.checkout.pickupAddresses +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    shippingFee: function shippingFee(cid) {
      return axios
        .get(
          apiUrl.checkout.shippingFee +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    walletPayment: function walletPayment(id) {
      return axios
        .get(
          apiUrl.checkout.walletPayment +
          id +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang(),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    updatedDraft: function updatedDraft(id, cid) {
      return axios
        .patch(
          apiUrl.checkout.updatedDraft +
          id +
          "/update_draft" +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          {},
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    paymentCard: function paymentCard() {
      return axios
        .get(
          apiUrl.checkout.paymentCard +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang()
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    cardVar: function cardVar(body) {
      return axios
        .post(
          apiUrl.checkout.cardVar +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang(),
          body,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    placeOrder: function placeOrder(body, id, cid) {
      return axios
        .patch(
          apiUrl.checkout.placeOrder +
          id +
          "/process_order_payment" +
          "?user_key=" +
          userKey() +
          "&lang=" +
          lang() +
          "&country_id=" +
          cid,
          body,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },

  wallet: {
    getCredit: function getCredit() {
      return axios
        .get(apiUrl.wallet.credit + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    getTranfer: function getTransfer() {
      return axios
        .get(apiUrl.wallet.tranfer + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },

  orderItemsList: {
    getItems: function getItems() {
      return axios
        .get(apiUrl.orderItems.getItems + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },
  disputes: {
    getData: function getData(page) {
      return axios
        .get(
          apiUrl.disputes.getDisputes +
          "?page=" +
          page +
          "&records_per_page=10&role=customer" +
          "&lang=" +
          lang(),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    getDispute: function getDispute(id) {
      return axios
        .get(apiUrl.disputes.Dispute + id + "?role=customer&lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    createReply: function createReply(id, body) {
      return axios
        .post(
          apiUrl.disputes.createReply +
          id +
          "/dispute_replies?role=customer" +
          "&lang=" +
          lang(),
          body,
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    createDispute: function createDispute(body) {
      return axios
        .post(apiUrl.disputes.create + "?lang=" + lang(), body, {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },
  review: {
    addReview: function addReview(body) {
      return axios
        .post(apiUrl.review.addReview + "?lang=" + lang(), body, {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },

  categories: {
    getProducts: function getProducts(p_id, c_id) {
      return axios
        .get(
          apiUrl.categories.products +
          "/all?country_id=" +
          c_id +
          "&lang=" +
          lang()
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    getItems: function getItems(
      id,
      type,
      page,
      records,
      price_high_to_low,
      price_low_to_high,
      new_arrivals,
      selectedCat
    ) {
      let categories = "";
      selectedCat &&
        selectedCat.length > 0 &&
        selectedCat.map(
          (cat) => (categories = categories + "&filter_by_category[]=" + cat)
        );
      return axios
        .get(
          apiUrl.allItems.getItems +
          type +
          "?country_id=" +
          id +
          "&lang=" +
          lang() +
          "&page=" +
          page +
          "&records_per_page=" +
          records +
          (price_high_to_low ? "&price_high_to_low=true" : "") +
          (price_low_to_high ? "&price_low_to_high=true" : "") +
          (new_arrivals ? "&order_by_new_arrivals=" + new_arrivals : "") +
          (selectedCat && selectedCat.length > 0 ? categories : ""),
          {
            headers: {
              "access-token": localStorage.getItem("access_token"),
              client: localStorage.getItem("client"),
              uid: localStorage.getItem("uid"),
            },
          }
        )
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }
          return responseCatch(error);
        });
    },

    allCategories: function allCategories(id) {
      return axios
        .get(apiUrl.categories.allCategories + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    getBanners: function getBanners(id) {
      return axios
        .get(apiUrl.categories.banner + id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    getCategory: function getCategory(id, c_id) {
      return axios
        .get(apiUrl.categories.getCategory + id + "?country_id=" + c_id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },
  profile: {
    changPass: function changPass(body) {
      return axios
        .patch(apiUrl.profile.changePass + "?lang=" + lang(), body, {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    update: function update(body) {
      return axios
        .patch(apiUrl.profile.update + "?lang=" + lang(), body, {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },

  header: {
    allBrands: function allBrands(c_id) {
      return axios
        .get(apiUrl.header.allBrands + c_id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },
    allCategories: function allCategories(c_id) {
      return axios
        .get(apiUrl.header.allCategories + c_id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },
    getCountries: function getCountries() {
      return axios
        .get(apiUrl.header.getCountries + "?lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },
    featuredCategories: function featuredCategories(c_id) {
      return axios
        .get(apiUrl.header.featuredCategories + c_id + "&lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },

    search: function search(c_id, search, product_type) {
      return axios
        .get(apiUrl.header.search + c_id + "&q=" + search + "&lang=" + lang() + "&product_type=" + product_type, {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        });
    },

    recentSearches: function recentSearches() {
      return axios
        .get(apiUrl.header.recentSearches + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },

    deleteRecent: function deleteRecent(id) {
      return axios
        .delete(apiUrl.header.deleteRecent + id + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    clearHistory: function clearHistory() {
      return axios
        .delete(apiUrl.header.clearHistory + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },
  auth: {
    signout: function signout() {
      return axios
        .delete(apiUrl.auth.signout + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return { success: true, response };
        })
        .catch((error) => {
          return { success: false, error: error };
        });
    },
    resetPass: function resetPass(body) {
      return axios
        .put(apiUrl.auth.resetPass + "?lang=" + lang(), body)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          } else if (error.response?.data?.success === false) {
            return {
              success: false,
              status: 410,
              message: error.response.data.errors[0],
            };
          } else {
            return responseCatch(error);
          }
        });
    },
    resetPassEmail: function resetPass(body) {
      return axios
        .post(apiUrl.auth.resetPass + "?lang=" + lang(), body)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          } else if (error.response?.data?.success === false) {
            return {
              success: false,
              status: 410,
              message: error.response.data.errors[0],
            };
          } else {
            return responseCatch(error);
          }
        });
    },
    validateToken: function validateToken() {
      return axios
        .get(apiUrl.auth.validateToken + "?lang=" + lang(), {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return {
              success: true,
              data: response.data,
              headers: response.headers,
            };
          } else {
            return {
              success: false,
              status: response.status,
              message: response.data.message,
            };
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
    validateLoginToken: function validateLoginToken(headers) {
      return axios
        .get(apiUrl.auth.validateToken + "?lang=" + lang(), headers)
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message:
                lang() === "ar" ? "دخول غير مرخص" : "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },
  platformData: {
    platformData: function getPlatformData() {
      return axios
        .get(apiUrl.platformData.getPlatform + "?lang=" + lang())
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            return {
              success: false,
              status: 401,
              message: "Unauthorized access",
            };
          }

          return responseCatch(error);
        });
    },
  },
  notify: {
    notifyMe: function notifyMe(body) {
      return axios
        .post(apiUrl.notify.notifyMe, body, {
          headers: {
            "access-token": localStorage.getItem("access_token"),
            client: localStorage.getItem("client"),
            uid: localStorage.getItem("uid"),
          },
        })
        .then((response) => {
          return responseThen(response);
        })
        .catch((error) => {
          return responseCatch(error);
        }
      );
    },
  },
};
