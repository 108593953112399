import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import $ from "jquery";
// eslint-disable-next-line
import Auth from "j-toker";

import SignupPage from "./components/pages/SignupPage";
import LoginPage from "./components/pages/LoginPage";
import HomePage from "./components/pages/HomePage";
import ProductHomePageOld from "./components/pages/ProductHomePageOld";
import ProductHomePage from "./components/pages/ProductHomePage";
import CustomerVendorShopPage from "./pages/CustomerVendorShopPage";
import ItemDetailPage from "./pages/customer/itemDetail/ItemDetailPage";
import AllItemsPage from "./pages/customer/AllItems/ItemsPage";
import SearchPage from "./pages/customer/AllItems/SearchPage";
import SelectCountryPage from "./pages/mobile/CountryMobilePage";
import HomeMob from "./pages/mobile/HomeMobilePage";
import HomePageMob from "./pages/mobile/HomePageMobile";
import SignupMobPageOpt1 from "./components/user-Auth-Option-1/SignupMobOption1Page";
import LoginMobPageOpt1 from "./components/user-Auth-Option-1/LoginMobOption1Page";
import VendorMobilePage from "./pages/mobile/VendorMobilePage";
import ResetPassword from "./components/user/ResetPassword";
import CartPage from "./pages/cart/CartPage";
import MyAccountLoggedOutMob from "./pages/mobile/MyAccountLoggedOutMob";
import AllCategoriesPage from "./pages/mobile/AllCategoriesPage";
import CategoriesPage from "./pages/customer/AllCategories/CategoriesPage";
import CartMobile from "./pages/mobile/CartMobile";
import ForgetPasswordMob from "./pages/mobile/ForgetPasswordMob";
import Routes from "./Routes";
import { set_width, set_country } from "./redux/actions/LayoutAction";
import { set_user } from "./redux/actions/AuthAction";
import "./assets/styles/general.scss";
import { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiJson } from "./Api";
import { domain } from "./constants";
import { useTranslation } from "react-i18next";
import { set_direction } from "./redux/actions/LayoutAction";
import axios from "axios";
import NotFound from "./components/NotFound/NotFound";
import { set_redirect_home } from "./redux/actions/NotFoundAction";
import ContactUsPage from "./pages/contactus/ContactUsPage";
import ContactUsMob from "./pages/mobile/footer-pages/ContactUs";
import ShippingPolicyPage from "./pages/shippingPolicy/ShippingPolicyPage";
import ShippingPolicyPageMob from "./pages/mobile/footer-pages/ShippingAndDelivery";
import ShippingAndDeliveryMobileApps from "./pages/mobile/footer-pages/ShippingAndDeliveryMobileApps";
import ConsumerRights from "./pages/footer-pages/ConsumerRights";
import ConsumerRightsMob from "./pages/mobile/footer-pages/ConsumerRights";
import PaymentOptions from "./pages/footer-pages/PaymentOptions";
import PaymentOptionsMob from "./pages/mobile/footer-pages/PaymentOptions";
import ReturnsRefunds from "./pages/footer-pages/ReturnsRefunds";
import ReturnsRefundsMob from "./pages/mobile/footer-pages/ReturnsRefunds";
import BulkOrder from "./pages/footer-pages/BulkOrder";
import BulkOrderMob from "./pages/mobile/footer-pages/BulkOrder";
import BrandsAZ from "./pages/footer-pages/BrandsAZ";
import BrandsAZMob from "./pages/mobile/footer-pages/BrandsAZ";
import Offers from "./pages/footer-pages/Offers";
import OffersMob from "./pages/customer/Offers/Offers";
import OffersSections from "./pages/footer-pages/OffersSections";
import NewOffersMob from "./pages/customer/NewOffers/NewOffers";
import NewOffersSections from "./pages/footer-pages/NewOffersSections";
import SectionVariantsPage from "./pages/customer/Offers/SectionVariantsPage";
import NewOffersSectionVariantsPage from "./pages/customer/NewOffers/NewOffersSectionVariantsPage";
import BrandsFavourites from "./pages/footer-pages/BrandsFavourites";
import BrandsFavouritesMob from "./pages/mobile/footer-pages/BrandsFavourites";

import BuildingConstruction from "./pages/category-homepages/building-and-construction";
import BuildingConstructionMob from "./pages/mobile/category-homepages-mob/building-and-construction-mob";

import VacuumCleaners from "./pages/category-homepages/vacuum-cleaners";
import VacuumCleanersMob from "./pages/mobile/category-homepages-mob/vacuum-cleaners-mob";

import PaintsPage from "./pages/category-homepages/paints-page";
import PaintsPageMob from "./pages/mobile/category-homepages-mob/paintspage-mob";

import CampingPage from "./pages/category-homepages/camping-page";
import CampingPageMob from "./pages/mobile/category-homepages-mob/campingpage-mob";
import HomeAppliancesPage from "./pages/category-homepages/home-appliances";
import HomeAppliancesPageMob from "./pages/mobile/category-homepages-mob/home-appliances-mob";

import CleanersPage from "./pages/category-homepages/cleaners";
import CleanersPageMob from "./pages/mobile/category-homepages-mob/cleaners-mob";


import ElectricalAccessoriesSalePage from "./pages/offers-landing-pages/electrical-accessories-sale";
import ElectricalAccessoriesSalePageMob from "./pages/offers-landing-pages/mob/electrical-accessories-sale-mob";


import RamadanPage from "./pages/offers/Ramadan";
import RamadanPageMob from "./pages/mobile/offers/RamadanMobile";

import B2bHomePage from "./pages/b2b/B2bHomePage";
import B2bHomePageMobile from "./pages/mobile/b2b/B2bHomePageMobile";

import DssPage from "./pages/category-homepages/summerdss";
import DssPageMob from "./pages/mobile/category-homepages-mob/dsspage-mob";

import AlisuqStory from "./pages/footer-pages/AlisuqStory";
import TermsAndConditions from "./pages/footer-pages/TermsAndConditions";
import TermsAndConditionsMob from "./pages/mobile/footer-pages/TermsAndConditions";
import TermsAndConditionsMobileApp from "./pages/mobile/footer-pages/TermsAndConditionsMobileApp";
import ConsumerRightsMobileApp from "./pages/mobile/footer-pages/ConsumerRightsMobileApp";
import PrivacyPolicy from "./pages/footer-pages/PrivacyPolicy";
import PrivacyPolicyMob from "./pages/mobile/footer-pages/PrivacyPolicy";
import AlisuqStoryMob from "./pages/mobile/footer-pages/AlisuqStory";
import { debounce } from "lodash";
import PrivacyPolicyMobileApp from "./pages/mobile/footer-pages/PrivacyPolicyMobileApp";
import ReturnsAndRefundsMobileApps from "./pages/mobile/footer-pages/ReturnsAndRefundsMobileApps";
import HomePageDesktop from "./pages/HomePageDesktop";
import NovemberSale from "./pages/customer/NovemberSale";
import NovemberSaleMob from "./pages/customer/NovemberSaleMob";
import SectionItemsPage from "./pages/customer/AllItems/SectionItemsPage";
import AliDay from "./pages/category-homepages/AliDay";
import AliDayMob from "./pages/mobile/category-homepages-mob/AliDayMob";


require("dotenv").config();

function App(props) {
  const history = useHistory();

  $.auth.configure({
    apiUrl: domain,
    emailSignInPath:
      "/auth/sign_in?country_id=" + props.countryId + "&lang=" + props.language,
    signOutPath: "/auth/sign_out?lang=" + props.language,
    emailRegistrationPath:
      "/auth?country_id=" + props.countryId + "&lang=" + props.language,
    tokenValidationPath: "/auth/validate_token?lang=" + props.language,
    authProviderPaths: {
      facebook: "/auth/facebook?lang=" + props.language,
      google: "/auth/google_oauth2?lang=" + props.language,
    },
  });

  axios.interceptors.response.use(
    (res) => {
      if (
        res.status === 404 &&
        !res.request.responseURL.includes("sign_out") &&
        window.location.pathname.split("/")[2] !== ""
      ) {
        props?.set_redirect_home(true);
      }
      return res;
    },
    (err) => {
      if (
        parseInt(err?.response?.status) === 404 &&
        !err.request.responseURL.includes("sign_out") &&
        window.location.pathname.split("/")[2] !== ""
      ) {
        props?.set_redirect_home(true);
      }
      return Promise.reject(err);
    }
  );

  function getScreenWidth(width) {
    if (width >= 1440) {
      return "xxxl";
    } else if (width >= 1200) {
      return "xxl";
    } else if (width >= 1024) {
      return "xl";
    } else if (width >= 992) {
      return "lg";
    } else if (width >= 768) {
      return "md";
    } else {
      return "sm";
    }
  }
  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let country = window.location.pathname.split("/")[1].split("-")[0];
    let lan = window.location.pathname.split("/")[1].split("-")[1];
    if (
      lan !== props.language &&
      (lan?.toLowerCase() === "ar" || lan?.toLowerCase() === "en")
    ) {
      i18n.changeLanguage(lan.toLowerCase());
      props.set_direction(lan.toLowerCase());
    }

    if (
      country !== props.countryUrl &&
      (country?.toLowerCase() === "saudi" ||
        country?.toLowerCase() === "ksa" ||
        country?.toLowerCase() === "uae" ||
        country?.toLowerCase() === "egypt")
    ) {
      if (country.toLowerCase() === "uae") {
        let c_id = 1;
        props.coutriesList.map((country) => {
          if (country.name_en === "UAE") {
            c_id = country.id;
          }
          return country;
        });
        props.set_country({
          country: "United Arab Emirates",
          countryId: c_id,
          countryUrl: "uae",
        });

        props.set_redirect_home(true);
      } else if (
        country.toLowerCase() === "saudi" ||
        country.toLowerCase() === "ksa"
      ) {
        let c_id = 1;
        props.coutriesList.map((country) => {
          if (country.name_en === "KSA") {
            c_id = country.id;
          }
          return country;
        });
        props.set_country({
          country: "Saudi Arabia",
          countryId: c_id,
          countryUrl: "saudi",
        });

        props.set_redirect_home(true);
      } else {
        let c_id = 1;
        props.coutriesList.map((country) => {
          if (country.name_en === "Egypt") {
            c_id = country.id;
          }
          return country;
        });
        props.set_country({
          country: "Egypt",
          countryId: c_id,
          countryUrl: "egypt",
        });

        props.set_redirect_home(true);
      }
    } else if (
      country &&
      lan &&
      (country !== props.countryUrl ||
        (lan?.toLowerCase() !== "ar" && lan?.toLowerCase() !== "en"))
    ) {
      props.set_redirect_home(true);
    } else {
      apiJson.header.getCountries().then((response) => {
        props.set_width(getScreenWidth(window.innerWidth));
        if (response.success) {
          let c_id = 1;
          response.data.map((country) => {
            if (
              country.name_en ===
              (props.country.toLowerCase() === "united arab emirates"
                ? "UAE"
                : props.country.toLowerCase() === "saudi arabia"
                  ? "KSA"
                  : "Egypt")
            ) {
              c_id = country.id;
            }
            return country;
          });
          props.set_country({ coutriesList: response.data, countryId: c_id });
        } else {
          props.coutriesList.length < 1 &&
            props.set_country({
              coutriesList: [
                {
                  id: 1,
                  name_ar: "الإمارات العربية المتحدة",
                  name_en: "UAE",
                },
                {
                  id: 2,
                  name_ar: "السعودية",
                  name_en: "KSA",
                },
                {
                  id: 3,
                  name_ar: "مصر",
                  name_en: "Egypt",
                },
              ],
              countryId: 1,
            });
        }
      });
    }
    if (lan === props.language || !lan) {
      i18n.changeLanguage(props.language);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedCallbackWidht =
    // eslint-disable-next-line
    useCallback(
      debounce((width) => {
        props.set_width(getScreenWidth(window.innerWidth));
      }, 500),
      []
    );
  window.addEventListener("resize", () =>
    debouncedCallbackWidht(window.innerWidth)
  );

  useEffect(
    () =>
      props.load
        ? $("body").css("overflow", "hidden")
        : $("body").css("overflow", "auto"),
    [props.load]
  );
  return (
    <>
      <Router history={history}>
        <div
          className={props.direction === "RTL" ? "change-direction-rtl" : ""}
        >
          <Switch>
            <Route
              exact
              path={"/"}
              render={() => (
                <Redirect
                  to={
                    "/" +
                    (props.countryUrl ? props.countryUrl : "uae") +
                    "-" +
                    (props.language ? props.language : "en") +
                    "/"
                  }
                />
              )}
            />
            <Route
              exact
              path={
                "/" + props.countryUrl + "-" + props.language + "/items/:type"
              }
            >
              <AllItemsPage />
            </Route>
            {/* <Route
              exact
              path={
                "/" + props.countryUrl + "-" + props.language + "/wholesale/items/:type"
              }
            >
              <AllItemsPage />
            </Route> */}
            <Route
              exact
              path={
                "/" + props.countryUrl + "-" + props.language + "/search/:type"
              }
            >
              <SearchPage />
            </Route>
            {/* <Route
              exact
              path={
                "/" + props.countryUrl + "-" + props.language + "/wholesale/search/:type"
              }
            >
              <SearchPage />
            </Route>
            <Route
              exact
              path={
                "/" + props.countryUrl + "-" + props.language + "/wholesale/section/:type/:id"
              }
            >
              <SectionItemsPage />
            </Route> */}
            <Route
              exact
              path={
                "/" + props.countryUrl + "-" + props.language + "/section/:type/:id"
              }
            >
              <SectionItemsPage />
            </Route>
            <Route
              exact
              path={"/" + props.countryUrl + "-" + props.language + "/cart"}
              component={
                props.width === "sm"
                  ? CartMobile
                  : props.width !== "" && CartPage
              }
            />{" "}
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/all-categories:filters?"
              }
            >
              {props.width === "sm" ? (
                <AllCategoriesPage />
              ) : (
                <CategoriesPage />
              )}
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/:category/:name"
              }
            >
              <ItemDetailPage />
            </Route>
            {/* <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/wholesale/:category/:name"
              }
            >
              <ItemDetailPage />
            </Route> */}
            <Route exact path="/login">
              {props.width === "sm" ? <LoginMobPageOpt1 /> : <LoginPage />}
            </Route>
            <Route exact path="/signup">
              {props.width === "sm" ? <SignupMobPageOpt1 /> : <SignupPage />}
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/vendor/shop/:id"
              }
            >
              {props.width === "sm" ? (
                <VendorMobilePage />
              ) : (
                <CustomerVendorShopPage />
              )}
            </Route>
            <Route exact path="/reset">
              {props.width === "sm" ? <ForgetPasswordMob /> : <ResetPassword />}
            </Route>
            <Route exact path="/not-found">
              {<NotFound />}
            </Route>
            <Route exact path="/country-mob">
              <SelectCountryPage />
            </Route>{" "}
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/my-account-lo-mob"
              }
              component={MyAccountLoggedOutMob}
            />
            <Route
              exact
              path={"/" + props.countryUrl + "-" + props.language + "/old"}
            >
              {props.width === "sm" ? <HomeMob /> : <ProductHomePageOld />}
            </Route>
            {/* <Route
              exact
              path={"/" + props.countryUrl + "-" + props.language + "/new"}
            >
              {props.width === "sm" ? <HomeMob /> : <ProductHomePage />}
            </Route> */}
            <Route
              exact
              path={"/" + props.countryUrl + "-" + props.language + "/"}
            >
              {props.width === "sm" ? <HomePageMob /> : <HomePage />}
            </Route>
            <Route
              exact
              path={"/" + props.countryUrl + "-" + props.language + "/new-home"}
            >
              {props.width === "sm" ? <HomeMob /> : <HomePageDesktop />}
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/consumer-rights"
              }
            >
              {props.width === "sm" ? (
                <ConsumerRightsMob />
              ) : (
                <ConsumerRights />
              )}
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/payment-options"
              }
            >
              {props.width === "sm" ? (
                <PaymentOptionsMob />
              ) : (
                <PaymentOptions />
              )}
            </Route>
            <Route
              exact
              path={"/" + props.countryUrl + "-" + props.language + "/offers"}
            >
              {props.width === "sm" ? <OffersMob /> : <OffersSections />}
            </Route>
            <Route
              exact
              path={"/" + props.countryUrl + "-" + props.language + "/offers/section/:id"}
            >
              <SectionVariantsPage />
            </Route>
            <Route
              exact
              path={"/" + props.countryUrl + "-" + props.language + "/new-offers"}
            >
              {props.width === "sm" ? <NewOffersMob /> : <NewOffersSections />}
            </Route>
            <Route
              exact
              path={"/" + props.countryUrl + "-" + props.language + "/new-offers/section/:id"}
            >
              <NewOffersSectionVariantsPage />
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/privacy-policy"
              }
            >
              {props.width === "sm" ? <PrivacyPolicyMob /> : <PrivacyPolicy />}
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/brand-favourites"
              }
            >
              {props.width === "sm" ? (
                <BrandsFavouritesMob />
              ) : (
                <BrandsFavourites />
              )}
            </Route>


            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/building-and-construction-materials"
              }
            >
              {props.width === "sm" ? (
                <BuildingConstructionMob />
              ) : (
                <BuildingConstruction />
              )}
            </Route>

            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/vacuum-cleaners"
              }
            >
              {props.width === "sm" ? (
                <VacuumCleanersMob />
              ) : (
                <VacuumCleaners />
              )}
            </Route>

            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/paints"
              }
            >
              {props.width === "sm" ? (
                <PaintsPageMob />
              ) : (
                <PaintsPage />
              )}
            </Route>

            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/winter-sale"
              }
            >
              {props.width === "sm" ? (
                <CampingPageMob />
              ) : (
                <CampingPage />
              )}
            </Route>

            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/aliday"
              }
            >
              {props.width === "sm" ? (
                <AliDayMob />
              ) : (
                <AliDay />
              )}
            </Route>

            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/ramadan-sale"
              }
            >
              {props.width === "sm" ? (
                <RamadanPageMob />
              ) : (
                <RamadanPage />
              )}
            </Route>


            {/* <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/wholesale"
              }
            >
              {props.width === "sm" ? (
                <B2bHomePageMobile />
              ) : (
                <B2bHomePage />
              )}
            </Route> */}






            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/home-appliances"
              }
            >
              {props.width === "sm" ? (
                <HomeAppliancesPageMob />
              ) : (
                <HomeAppliancesPage />
              )}
            </Route>

            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/cleaners"
              }
            >
              {props.width === "sm" ? (
                <CleanersPageMob />
              ) : (
                <CleanersPage />
              )}
            </Route>



            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/electrical-accessories-sale"
              }
            >
              {props.width === "sm" ? (
                <ElectricalAccessoriesSalePageMob />
              ) : (
                <ElectricalAccessoriesSalePage />
              )}
            </Route>





            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/11-11-sale"
              }
            >
              {props.width === "sm" ? (
                <NovemberSaleMob />
              ) : (
                <NovemberSale />
              )}
            </Route>

            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/dss"
              }
            >
              {props.width === "sm" ? (
                <DssPageMob />
              ) : (
                <DssPage />
              )}
            </Route>



            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/return-refunds"
              }
            >
              {props.width === "sm" ? (
                <ReturnsRefundsMob />
              ) : (
                <ReturnsRefunds />
              )}
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/return-refunds-mob-app"
              }
            >
              <ReturnsAndRefundsMobileApps />
            </Route>
            <Route
              exact
              path={
                "/" + props.countryUrl + "-" + props.language + "/alisuq-story"
              }
            >
              {props.width === "sm" ? <AlisuqStoryMob /> : <AlisuqStory />}
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/terms-and-conditions"
              }
            >
              {props.width === "sm" ? (
                <TermsAndConditionsMob />
              ) : (
                <TermsAndConditions />
              )}
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/terms-and-conditions-mob-app"
              }
            >
              <TermsAndConditionsMobileApp />
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/shipping-and-delivery-mob-app"
              }
            >
              <ShippingAndDeliveryMobileApps />
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/consumer-rights-mob-app"
              }
            >
              <ConsumerRightsMobileApp />
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/privacy-policy-mob-app"
              }
            >
              <PrivacyPolicyMobileApp />
            </Route>
            <Route
              exact
              path={
                "/" + props.countryUrl + "-" + props.language + "/contact-us"
              }
            >
              {props.width === "sm" ? <ContactUsMob /> : <ContactUsPage />}
            </Route>{" "}
            <Route
              exact
              path={
                "/" + props.countryUrl + "-" + props.language + "/brands-az"
              }
            >
              {props.width === "sm" ? <BrandsAZMob /> : <BrandsAZ />}
            </Route>
            <Route
              exact
              path={
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/shipping-policy"
              }
            >
              {props.width === "sm" ? (
                <ShippingPolicyPageMob />
              ) : (
                <ShippingPolicyPage />
              )}
            </Route>
            <Route
              exact
              path={
                "/" + props.countryUrl + "-" + props.language + "/bulk-order"
              }
            >
              {props.width === "sm" ? <BulkOrderMob /> : <BulkOrder />}
            </Route>
            <Route
              path={"/" + props.countryUrl + "-" + props.language + "/"}
              component={Routes}
            />
          </Switch>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
          />
          {/* {props.load && <CenteredSpinner show={props.load} />} */}
        </div>
        {props.redirect && (
          <Redirect to={"/" + props.countryUrl + "-" + props.language + "/"} />
        )}
      </Router>
      <div
        id="dropdown-overlay"
        style={{ display: props.width === "sm" && "none" }}
      ></div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
    direction: state.language.direction,
    language: state.language.language,
    countryId: state.language.countryId,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    coutriesList: state.language.coutriesList,
    redirect: state.notFound.redirect,
    user: state.auth.user,
    load: state.layout.loading,
  };
};

const mapDispatchToProps = {
  set_width,
  set_direction,
  set_user,
  set_country,
  set_redirect_home,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);