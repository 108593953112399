import React, { Fragment, useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import InputHints from "react-input-hints";
import {
  IoPersonOutline,
  IoChevronForward,
  IoChevronBack,
  IoHeartOutline,
  IoSearchOutline,
} from "react-icons/io5";
import { FiTruck } from "react-icons/fi";
import { FaMicrophone } from "react-icons/fa"
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";
import MobileIcon from "../../assets/icons/mobile.png";
import CartIcon from "../../assets/icons/cart_ico.svg";
import SaleButton from "../../assets/images/footer-page-images/sale-button.jpg";
import AndroidLogo from "../../assets/images/header/android-logo.png";
import AppleLogo from "../../assets/images/header/apple.png";
import Logo from "../../assets/images/logo.png";
import { ReactComponent as UAEFLAG } from "../../assets/icons/uae.svg";
import { ReactComponent as KSAFLAG } from "../../assets/icons/ksa.svg";
import { ReactComponent as EGYPTFLAG } from "../../assets/icons/egypt.svg";
import SearchPopover from "./SearchPopover";
import { MdMenu, MdArrowDropDown } from "react-icons/md";
import AllCategories from "./AllCategories";
import IndividualCategory from "./IndividualCategory";
import { useOutsideAlerter } from "../../utils/CustomHooks";
import SignInDropdown from "./SignInDropdown";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { set_country } from "../../redux/actions/LayoutAction";
import { apiJson } from "../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_categories, set_search } from "../../redux/actions/HeaderAction";
import { set_cart_items } from "../../redux/actions/CartAction";
import { set_direction } from "../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { useCallback } from "react";
import $ from "jquery";
import { set_user } from "../../redux/actions/AuthAction";
import "swiper/swiper.scss";
import "react-dropdown/style.css";
import "../../assets/styles/header/websiteheaderdesktop.scss";
import "../../assets/styles/utils.scss";
import ReactGA from 'react-ga';
import dsspic from "../../assets/images/individual-pages/dss/saleb11.png";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import listening_icon from '../../assets/images/listening_icon.svg'

ReactGA.initialize('UA-159045109-2');
require("dotenv").config();

const en_translation = require("../../assets/locales/en/translation.json");
const ar_translation = require("../../assets/locales/ar/translation.json");

const WebsiteHeader = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [shipto, setshipto] = useState(
    props.country.toLowerCase() === "United Arab Emirates".toLowerCase()
      ? { name: "UAE", flag: <UAEFLAG /> }
      : props.country.toLowerCase() === "Saudi Arabia".toLowerCase()
        ? { name: "KSA", flag: <KSAFLAG /> }
        : { name: "Egypt", flag: <EGYPTFLAG /> }
  );
  const { t, i18n } = useTranslation();

  const BECOME_SELLER_LINK = process.env.REACT_APP_VENDOR_DOMAIN;

  const [isShipClick, setIsShipClick] = useState(false);
  const [isSigninClick, setisSigninClick] = useState(false);
  const [isUser, setisUser] = useState(false);
  const [isSearchClick, setIsSearchClick] = useState(false);
  const [recentSearch, setrecentSearch] = useState([]);
  const [recentvalue, setrecentvalue] = useState("");
  const [headerHeight, setHeaderHeight] = useState(0);
  const individualCategoryRef = useRef(null);
  const wrapperRef = useRef(null);
  const signinRef = useRef(null);
  const searchRef = useRef(null);
  const allCategoryRef = useRef(null);
  const [isClick, setIsClick] = useState(false);
  const [isCategoryClick, setIsCategoryClick] = useState(false);
  const [isBrandsHover, setIsBrandsHover] = useState(false);
  const [getAppDropdownIsVisible, setGetAppDropdownIsVisible] = useState(false);

  const [subCat, setSubCat] = useState(
    props.categories.length > 0 ? props.categories[0].sub_categories : []
  );
  const [selecetdCat, setSelectedCat] = useState(
    props.categories.length > 0
      ? props.categories[0][`title_${props.lang}`]
      : { title: "", image: "" }
  );
  const [catOverflown, setCatOverflown] = useState(false);
  const [catRightOverflowEnd, setCatRightOverflowEnd] = useState(false);
  const [catLeftOverflow, setCatLeftOverflow] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [searching, setSearching] = useState(false);
  const [loader, setLoader] = useState(false);

  const [brands, setBrands] = useState([]);
  const [filtered_brands, setFilteredBrands] = useState([]);
  const [searched_brand, setSearchedBrand] = useState([]);

  useOutsideAlerter(wrapperRef, setIsShipClick);
  useOutsideAlerter(wrapperRef, setGetAppDropdownIsVisible);
  useOutsideAlerter(signinRef, setisSigninClick);
  useOutsideAlerter(searchRef, setIsSearchClick);
  useOutsideAlerter(allCategoryRef, setIsClick);
  useOutsideAlerter(individualCategoryRef, setIsCategoryClick);

  let message = "";

  const {
    transcript,
    listening,
    resetTranscript,
  } = useSpeechRecognition();

  useEffect(() => {
    if(transcript){
      setrecentvalue(transcript)
      setIsSearchClick(true);
    }

    !listening && transcript && performSearch(transcript)
  }, [transcript, listening])

  const changeLan = (lang) => {
    i18n.changeLanguage(lang);
    props.set_direction(lang);
    history.push({
      pathname:
        "/" +
        props.countryUrl +
        "-" +
        lang +
        "/" +
        window.location.pathname.replace(/(?:.*?\/){2}/, ""),
      state: location.state,
    });
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const performSearch = function (text) {
    // if (didYouMean(text.trim().replace(/\s\s+/g, ' '), keywords)) {
    //   text = didYouMean(text.trim().replace(/\s\s+/g, ' '), keywords);
    // }
    text = text.replace("&", "");

    let product_type = window.location.href.includes("/wholesale") ? "wholesale" : "retail";

    apiJson.header.search(props.countryId, text.trim().replace(/\s\s+/g, ' '), product_type).then(function (response) {
      setSearching(false);
      if (response.success) {
        props.set_search({
          searchCat: response.data.categories,
          searchBrands: response.data.brands,
          searchProducts: response.data.products,
          searchServices: response.data.services,
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response?.status === 401) {
          props.set_user({ isSignedIn: false, user: {} });
          localStorage.removeItem("access_token");
          localStorage.removeItem("client");
          localStorage.removeItem("uid");
          document.cookie =
            "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            if (response.status == 503) {
              message = t("errors.network");
              showError();
            }
            else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        }
      }
    });
  };

  const debouncedCallback =
    // eslint-disable-next-line
    useCallback(
      debounce((text) => performSearch(text), 1000),
      []
    );

  useEffect(() => {
    let el = $(".website-header-desktop-latest");
    let startingPoint = el.position().top + el.outerHeight(true);
    setHeaderHeight(startingPoint);
  }, []);

  useEffect(
    () =>
      setCatOverflown(
        document.getElementsByClassName("right")[0]?.scrollWidth >
        document.getElementsByClassName("right")[0]?.clientWidth
      ),
    [props.featuredCategories]
  );

  const onSeacrhChange = (text) => {
    setIsSearchClick(true);
    setrecentvalue(text);
    if (text.length < 2) {
      return [];
    } else {
      !searching && setSearching(true);
      debouncedCallback(text);
    }
  };

  const handleSearchClcik = (e) => {
    setIsSearchClick(true);
  };

  const onAllCategoriesHover = () => {
    setIsClick(true);
    setIsBrandsHover(false);
    setIsCategoryClick(false);
    setIsSearchClick(false);
    setIsShipClick(false);
  };

  const onIndividualCategoryHover = (sub_cat, cat) => {
    setSubCat(sub_cat);
    setSelectedCat(cat);
    setIsCategoryClick(true);
    setIsClick(false);
    setIsSearchClick(false);
    setIsShipClick(false);
    setIsBrandsHover(false);
    setGetAppDropdownIsVisible(false);
  };

  const onBrandHover = () => {
    setIsBrandsHover(!isBrandsHover);
    setIsCategoryClick(false);
    setIsClick(false);
    setIsSearchClick(false);
    setIsShipClick(false);
    setGetAppDropdownIsVisible(false);
  };

  const onMouseMoveUp = () => {
    setIsClick(false);
    setIsCategoryClick(false);
    setIsBrandsHover(false);
  };

  const handleSignin = () => {
    if (localStorage.getItem("access_token")) {
      setisSigninClick(!isSigninClick);
    } else {
      setRedirectToLogin(true);
      const timer = setTimeout(() => {
        setRedirectToLogin(false);
      }, 1000);
      clearTimeout(timer);
    }
  };
  const RedirectToHomeScreen = () => {
    if (
      location.pathname === "/uae-en/" ||
      location.pathname === "/saudi-en/" ||
      location.pathname === "/egypt-en/"
    ) {
      window.location.reload(false);
    }
    setRedirectToHome(true);
    const timer = setTimeout(() => {
      setRedirectToHome(false);
    }, 1000);
    clearTimeout(timer);
  };

  const changeCountry = async (country) => {
    if (country === "UAE") {
      let c_id = 1;
      props.coutriesList.map((country) => {
        if (country.name_en === "UAE") {
          c_id = country.id;
        }
        return country;
      });
      props.set_country({ country: "United Arab Emirates", countryId: c_id });
    } else if (country === "KSA") {
      let c_id = 1;
      props.coutriesList.map((country) => {
        if (country.name_en === "KSA") {
          c_id = country.id;
        }
        return country;
      });
      props.set_country({ country: "Saudi Arabia", countryId: c_id });
    } else {
      let c_id = 1;
      props.coutriesList.map((country) => {
        if (country.name_en === "Egypt") {
          c_id = country.id;
        }
        return country;
      });

      props.set_country({ country: "Egypt", countryId: c_id });
    }
  };

  useEffect(() => {
    (props.country.toLowerCase() === "saudi arabia"
      ? "saudi"
      : props.country.toLowerCase() === "united arab emirates"
        ? "uae"
        : "egypt") !== props.countryUrl &&
      props.set_country(
        props.country.toLowerCase() === "saudi arabia"
          ? { countryUrl: "saudi" }
          : props.country.toLowerCase() === "united arab emirates"
            ? { countryUrl: "uae" }
            : { countryUrl: "egypt" }
      );
    (props.country.toLowerCase() === "saudi arabia"
      ? "saudi"
      : props.country.toLowerCase() === "united arab emirates"
        ? "uae"
        : "egypt") !== props.countryUrl &&
      window.location.pathname !== "/login" &&
      history.push(
        "/" +
        (props.country.toLowerCase() === "saudi arabia"
          ? "saudi"
          : props.country.toLowerCase() === "united arab emirates"
            ? "uae"
            : "egypt") +
        "-" +
        props.lang +
        "/"
      );
    apiJson.header.allBrands(props.countryId).then((response) => {
      if (response.success) {
        setBrands(response.data);
        setFilteredBrands(response.data);
      } else if (!response.status) {
        message = response.message // eslint-disable-line react-hooks/exhaustive-deps
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          if (response.status == 503) {
            message = t("errors.network");
            showError();
          }
          else {
            message = t("errors.wrong");
            showError();
          }
        }
      }
    });
    apiJson.header.allCategories(props.countryId).then((response) => {
      if (response.success) {
        props.set_categories({
          allCategories: response.data,
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          if (response.status == 503) {
            message = t("errors.network");
            showError();
          }
          else {
            message = t("errors.wrong");
            showError();
          }
        }
      }
    });
    apiJson.header.featuredCategories(props.countryId).then((response) => {
      if (response.success) {
        props.set_categories({
          featuredCategories: response.data,
        });
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          if (response.status == 503) {
            message = t("errors.network");
            showError();
          }
          else {
            message = t("errors.wrong");
            showError();
          }
        }
      }
    });
  }, [props.countryId]);

  const deleteRecent = (id) => {
    setLoader(true);
    apiJson.header.deleteRecent(id).then((response) => {
      setLoader(false);
      if (response.success) {
        setrecentSearch(recentSearch.filter((ele) => ele.id !== id));
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          if (response.status == 503) {
            message = t("errors.network");
            showError();
          }
          else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      }
    });
  };

  const clearRecentHistory = () =>
    apiJson.header.clearHistory().then((response) => {
      setLoader(false);
      if (response.success) {
        setrecentSearch([]);
      } else if (!response.status) {
        message = response.message
          ? response.message !== ""
            ? response.message
            : t("errors.wrong")
          : t("errors.wrong");
        showError();
      } else {
        if (response.status >= 400 && response.status < 500) {
          message = response.message
            ? response.message
            : response.errors
              ? response.errors
              : t("errors.wrong");
          showError();
        } else {
          if (response.status == 503) {
            message = t("errors.network");
            showError();
          }
          else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      }
    });

  useEffect(
    () =>
      localStorage.getItem("access_token") &&
      recentvalue === "" &&
      apiJson.header.recentSearches().then((response) => {
        if (response.success) {
          setrecentSearch(response.data);
        } else if (!response.status) {
          message = response.message // eslint-disable-line react-hooks/exhaustive-deps
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          if (response?.status === 401) {
            props.set_user({ isSignedIn: false, user: {} });
            localStorage.removeItem("access_token");
            localStorage.removeItem("client");
            localStorage.removeItem("uid");
            document.cookie =
              "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              if (response.status == 503) {
                message = t("errors.network");
                showError();
              }
              else {
                message = response.message
                  ? response.message !== ""
                    ? response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          }
        }
      }),
    [recentvalue]
  );

  useEffect(() => {
    var total = 0;
    props.cart_items?.line_items?.map((item) => {
      if (item.orderable_type === "Product") {
        total = total + parseInt(item.quantity);
      } else if (item.orderable_type === "Service") {
        total = total + 1;
      }
      props.set_cart_items({ cart_items_length: total });
      return item;
    });
    if (props.cart_items?.line_items?.length === 0) {
      props.set_cart_items({ cart_items_length: total });
    }
  }, [props.cart_items?.line_items]); // eslint-disable-line react-hooks/exhaustive-deps

  const cls =
    props.lang?.toLowerCase() === "en"
      ? "justify-content-start"
      : "justify-content-end";

  useEffect(() => {
    if (isClick || isCategoryClick) {
      $("#dropdown-overlay").addClass("active-overlay");
      $("#dropdown-overlay").css("top", `${headerHeight}px`);
    } else {
      $("#dropdown-overlay").removeClass("active-overlay");
    }
  }, [isClick, isCategoryClick]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkSwiperOverflow = () => {
    setCatOverflown(
      document.getElementsByClassName("right")[0]?.scrollWidth >
      document.getElementsByClassName("right")[0]?.clientWidth
    );
  };
  const debouncedCallbackWidht =
    // eslint-disable-next-line
    useCallback(
      debounce((width) => checkSwiperOverflow(), 1000),
      []
    );

  window.addEventListener("resize", () =>
    debouncedCallbackWidht(window.screen.width)
  );

  const handleForwardIconClick = () => {
    if (catOverflown) {
      const elementWidth = document.querySelector(
        ".website-header-desktop-latest .categories-container .right .category-name"
      ).scrollWidth;
      const toScroll = document.querySelector(
        ".website-header-desktop-latest .categories-container .right"
      );
      if (props.language === "en") {
        toScroll.scrollLeft += elementWidth;
      } else {
        toScroll.scrollLeft -= elementWidth;
      }
      var $width = $("#right").outerWidth();
      var $scrollWidth = $("#right")[0].scrollWidth;
      if (
        (props.language === "en" &&
          document.querySelector(
            ".website-header-desktop-latest .categories-container .right"
          ).scrollLeft >=
          parseInt($scrollWidth) - parseInt($width)) ||
        (props.language === "ar" &&
          document.querySelector(
            ".website-header-desktop-latest .categories-container .right"
          ).scrollLeft -
          1 <=
          parseInt($width) - parseInt($scrollWidth))
      ) {
        setCatRightOverflowEnd(true);
      }
      !catLeftOverflow && setCatLeftOverflow(true);
    }
  };

  const handleBackwordIconClick = () => {
    if (catOverflown) {
      const elementWidth = document.querySelector(
        ".website-header-desktop-latest .categories-container .right .category-name"
      ).scrollWidth;
      const toScroll = document.querySelector(
        ".website-header-desktop-latest .categories-container .right"
      );
      if (props.language === "en") {
        toScroll.scrollLeft -= elementWidth;
      } else {
        toScroll.scrollLeft += elementWidth;
      }
      if (
        (props.language === "en" &&
          document.querySelector(
            ".website-header-desktop-latest .categories-container .right"
          ).scrollLeft <= 0) ||
        (props.language === "ar" &&
          document.querySelector(
            ".website-header-desktop-latest .categories-container .right"
          ).scrollLeft +
          1 >=
          0)
      ) {
        setCatLeftOverflow(false);
      }
      catRightOverflowEnd && setCatRightOverflowEnd(false);
    }
  };

  const onSearchUserInputChanged = (e) => {
    setSearchedBrand(e.target.value);
    if (e.target.value) {
      var filtered_brands = brands.filter((brand) =>
        brand.name_en.toLowerCase().includes(e.target.value.toLowerCase())
      );
    }
    else {
      var filtered_brands = brands;
    }
    setFilteredBrands(filtered_brands);
  };

  const onSearchBtnClicked = (e) => {
    e.preventDefault();
    setIsSearchClick(false);

    history.push({
      pathname:
        "/" +
        props.countryUrl +
        "-" +
        props.language +
        "/search/products?q=" + recentvalue.replace("&", "_"),
      state: location.state,
    });
  }

  return (
    <Fragment>
      <div>
        {/* <Link to="/uae-en/dss">
        <img style={{ width: "100%", height: "auto" }} width="585" height="80" className="dssstrip" src={dsspic}></img>
        </Link> */}
        {/* <h3>DUBAI SUMMER SURPRISES IS HERE - UPTO 75% OFF ON YOUR FAVOURITE BRANDS</h3> */}
      </div>
      <div className="website-header-desktop-latest">
        <div className="top-row col-lg-12">
          <Row className="top-nav mx-auto mb-md-3 mb-lg-2">
            <Col
              md={4}
              className={`contact-info-col d-flex ${cls}`}
              style={{ direction: "ltr" }}
            >
              <div className="phone-number">
                <PhoneIcon class="w16" />
                <ReactGA.OutboundLink
                  eventLabel="contact_no"
                  to={"tel:971543064845"}
                // target="_blank"
                // trackerNames={['tracker2']}
                >
                  054-306-4845
                </ReactGA.OutboundLink>
              </div>
              <div className="email">
                <EmailIcon class="w16" />{" "}
                <a href="mailto:sales@alisuq.com">sales@alisuq.com</a>
              </div>
            </Col>
            <Col md={4}>
              <p className="m-0 text-center freshiptxt">
                <FiTruck size={18} />&nbsp;&nbsp;
                {props.language == "en" ? en_translation["homePage"]["free-shipping-text"] : ar_translation["homePage"]["free-shipping-text"]}
                {/* {t("homePage.free-shipping-text")} */}
              </p>
            </Col>
            <Col md={4} className="navigation-col">
              {/* <Link
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.lang +
                    "/items/services",
                  search:
                    window.location.pathname.includes("/items/services") &&
                      !location.state
                      ? window.location.search
                      : "",
                  state: undefined,
                }}
                className="shop-nav navigation-link"
              >
                {

                  t("vendor-shop.services-label")
                }
              </Link> */}
              <span
                className="home-nav navigation-link pointer"
                style={{ position: "relative" }}
              >
                <span
                  onClick={() =>
                    setGetAppDropdownIsVisible(true)
                  }
                  ref={wrapperRef}
                >
                  <img src={MobileIcon} style={{ height: "25px" }} alt="" />
                  {t("homePage.get-app")}
                </span>
                {getAppDropdownIsVisible ? (
                  <div className="get-app-dropdown" ref={wrapperRef}>
                    <div className="getapp">
                      <p>GET APP</p>
                    </div>
                    {/* <img className="qrcode" src={alisuqqr} alt="" /> */}

                    <div onClick={() => window.open("https://play.google.com/store/apps/details?id=com.alisuq", "_blank")}>
                      <span className="getappslinks">
                        <img src={AndroidLogo} alt="" />
                        Android
                      </span>
                    </div>
                    <span className="getappslinks">
                      <img src={AppleLogo} alt="" />
                      iPhone
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </span>
              <Link
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.lang +
                    "/items/products",
                  search: "",
                  state: undefined,
                }}
                className="shop-nav navigation-link"
              >
                {t("items.header.products")}
              </Link>
              <Link
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.lang +
                    "/items/services",
                  search: "",
                  state: undefined,
                }}
                className="shop-nav navigation-link"
              >
                {
                  props.language == "en" ?
                    en_translation["items"]["header"]["services"]
                    :
                    ar_translation["items"]["header"]["services"]
                }
              </Link>

              <a
                href={`${BECOME_SELLER_LINK}`}
                className="become-seller-nav navigation-link"
              >
                {t("homePage.become-seller")}
              </a>
            </Col>
          </Row>
        </div>
        <div
          className="navigation-header all-page-container mx-auto"
          onMouseEnter={onMouseMoveUp}
        >
          <Row className="bottom-nav mx-auto mb-md-3 mb-lg-2">
            <div className="header-parts leftheader">
              <div className="site-logo">
                <a href={"/" + props.countryUrl + "-" + props.language} className="site-header-logo">
                  <img src={Logo} alt="" />
                </a>
              </div>
            </div>
            <div className="header-parts centertheader">
              <div className="logo-and-search-col d-flex align-items-center px-md-0">
                <div
                  className="input-group d-flex align-items-center "
                  ref={searchRef}
                >
                  <form onSubmit={onSearchBtnClicked} className="search-form">
                    <InputHints
                      type="search"
                      onChange={(e) => {
                        onSeacrhChange(e.target.value)
                        !e.target.value && resetTranscript()
                      }}
                      className="form-control"
                      placeholders={[
                        t("homePage.search-ph1"),
                        t("homePage.search-ph2"),
                        t("homePage.search-ph3"),
                        t("homePage.search-ph4"),
                        t("homePage.search-ph5"),
                      ]}
                      aria-label="Search"
                      aria-describedby="search-addon"
                      value={recentvalue}
                      onClick={handleSearchClcik}
                    />
                    {listening ? (
                      <img
                        src={listening_icon}
                        alt="Listening"
                        style={{
                          marginTop: "5px",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={SpeechRecognition.stopListening}
                      />
                    ) : (
                      <FaMicrophone
                        size="20"
                        onClick={SpeechRecognition.startListening}
                        style={{
                          marginTop: "8px",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    <span
                      className="input-group-text search-icon pointer"
                      id="search-addon"
                    >
                      <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <button className={props.lang === "ar" ? "search-btn-ar" : "search-btn"}><IoSearchOutline /></button>
                      </div>
                      {/* {searching ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                      <ClipLoader color="#fff" loading size={18} />
                    </div>
                  ) : (
                    <FaSearch />
                  )} */}
                    </span>
                  </form>
                  {isSearchClick && (
                    <SearchPopover
                    recent={recentSearch}
                    recentValue={recentvalue}
                      clearHistory={() => {
                        setLoader(true);
                        clearRecentHistory();
                      }}
                      deleteRecent={(id) => deleteRecent(id)}
                      loader={loader}
                      setRecentValue={(val) => onSeacrhChange(val)}
                      searching={searching}
                    />
                  )}
                </div>

              </div>



            </div>
            <div className="header-parts rightheader">
              <div className="navigation-col d-flex justify-content-between align-items-center px-md-0 px-xl-0">

                {
                  props.language == "en" ?
                    <a href="https://alisuq.com/uae-ar/"
                      className="lang"
                    >
                      العربية
                    </a>
                    :
                    <a href="https://alisuq.com/uae-en/"
                      className="lang"
                    >
                      English
                    </a>
                }

                <div
                  className="signin-col pointer ml-auto d-flex justify-content-between align-items-center mr-auto"
                  onClick={handleSignin}
                  ref={signinRef}
                >
                  <span className="sign-in navigation-link">
                    {localStorage.getItem("access_token") ? (
                      <div className="my-account-label-div">
                        <IoPersonOutline
                          style={{
                            fontSize: "25px",
                            marginInlineStart: "5px",
                            marginInlineEnd: "5px",

                          }}
                        />
                        <div className="d-flex flex-column">

                          <p

                            style={{ whiteSpace: "nowrap", overflowX: "hidden" }}
                          >

                            Hello {""}
                            {props.user?.first_name_en}
                          </p>

                        </div>
                        <div className="account-drop-svg">
                          <MdArrowDropDown size={20} />
                        </div>
                      </div>
                    ) : (
                      <span>
                        {t("homePage.sign-in")}
                        <IoPersonOutline
                          style={{
                            fontSize: "25px",
                            marginInlineStart: "5px",
                            marginInlineEnd: "5px",
                          }}
                        />
                      </span>
                    )}
                  </span>
                  {isSigninClick && (
                    <div className="signin-dropdown-menu">
                      <SignInDropdown
                        isUser={isUser}
                        handleClick={() => {
                          setisUser(!isUser);
                          setisSigninClick(!isSigninClick);
                        }}
                      />
                    </div>
                  )}
                </div>



                <Link
                  style={{
                    color: "#404553",
                    textDecoration: "none",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                  to={"/" + props.countryUrl + "-" + props.lang + "/wishlist"}
                >
                  <div className="item-row d-flex justify-content align-items-center">
                    <div className="item-icon me-2 d-inline-flex justify-content-center align-items-center p-1">
                      <IoHeartOutline
                        style={{
                          fontSize: "25px",
                        }}
                      />
                    </div>

                  </div>
                </Link>






                <Link
                  style={{
                    color: "#404553",
                    textDecoration: "none",
                    paddingLeft: "10px",
                    paddingRight: "10px",

                  }}
                  to={"/" + props.countryUrl + "-" + props.lang + "/cart"}
                >
                  <div className="cart-col ml-auto pl-md-0">

                    <span>
                      <img
                        className="price navigation-link"
                        src={CartIcon}
                        style={{ height: "35px" }}
                        alt=""
                      />
                    </span>
                    <span
                      style={{
                        right: props.lang === "ar" ? "35%" : "0px",
                      }}
                      className="cart-counter"
                    >
                      {props.cart_items_length ? props.cart_items_length : 0}
                    </span>
                  </div>
                </Link>
                <div className="rhd-icons">

                </div>
                <div className="rhd-icons-block">
                  <a className="rhd-link">
                    <span className="rhd-icon">

                    </span>


                  </a>
                </div>
                <div className="rhd-icons">

                </div>
                <div className="rhd-icons">

                </div>

              </div>
            </div>
          </Row>
        </div>
        <div className="categories-container d-flex justify-content-center">
          <div className="wrapper all-page-container d-flex justify-content-start">
            <div
              className="left all-categories d-flex justify-content-between align-items-center"
              onMouseEnter={onAllCategoriesHover}
            >
              <div className="dropdown-arrow">
                <MdMenu />
              </div>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname:
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.lang +
                    "/items/products",
                  state: { title: [] },
                }}
              >
                <div className="pointer text">
                  {t("homePage.all-categories")}
                </div>
              </Link>

            </div>
            <IoChevronBack
              className={
                catOverflown && catLeftOverflow
                  ? `mt-mb-atuo pointer`
                  : `invisible`
              }
              size="20px"
              onClick={handleBackwordIconClick}
              style={{
                transform:
                  props.lang === "en"
                    ? "unset"
                    : `rotate(
                180deg
                )`,
              }}
            />
            <div id="right" className="right ">
              {props.featuredCategories.map((category) => (
                <div
                  className={` category-name pointer d-flex justify-content-center align-items-center fw-600`}
                  onMouseEnter={() => {
                    if (category.sub_categories.length > 0) {
                      onIndividualCategoryHover(category.sub_categories, {
                        title: category[`title_${props.lang}`],
                        image: category.image,
                      });
                    } else {
                      setIsCategoryClick(false);
                      setIsClick(false);
                      setIsSearchClick(false);
                      setIsShipClick(false);
                      setIsBrandsHover(false);
                    }
                  }}
                  key={category.id}
                >
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to={{
                      pathname: "/" +
                        props.countryUrl +
                        "-" +
                        props.lang +
                        "/items/products",
                      search: "?filter_by_category%5B%5D=" + category.id
                    }}
                  >
                    {category[`title_${props.lang}`]}
                  </Link>
                </div>
              ))}



              <div
                className={` category-name pointer d-flex justify-content-center align-items-center fw-600`}
                onClick={() => {
                  if (brands.length > 0) {
                    onBrandHover();
                  } else {
                    setIsBrandsHover(false);
                    setIsCategoryClick(false);
                    setIsClick(false);
                    setIsSearchClick(false);
                    setIsShipClick(false);
                  }
                }}
              >
                {t("homePage.brands")}
              </div>
              <div className=" category-name pointer d-flex justify-content-center align-items-center fw-600">
                <Link
                  to={"/" + props.countryUrl + "-" + props.language + "/new-offers"} style={{textDecoration: "none"}}>
                  <span className="off50">Brand of the Week</span>
                  {/* <span className="offr">Offer</span> */}
                  {/* <span className="offr"><span className="offranim">Back to School</span></span> */}
                </Link>
              </div>
              <div className=" category-name pointer d-flex justify-content-center align-items-center fw-600">
                <Link
                  to={"/" + props.countryUrl + "-" + props.language + "/offers"} style={{textDecoration: "none"}}>
                  <span className="off50">Clearance Sale</span>
                  {/* <span className="offr">Offer</span> */}
                  {/* <span className="offr"><span className="offranim">Back to School</span></span> */}
                </Link>
              </div>
              <Link
                to={"/" + props.countryUrl + "-" + props.language + "/electrical-accessories-sale"}

                onMouseEnter={() => {
                  setIsBrandsHover(false);
                  setIsCategoryClick(false);
                  setIsClick(false);
                  setIsSearchClick(false);
                  setIsShipClick(false);
                }}
                style={{ textDecoration: "none", backgroundColor: "red", color: "white", padding: "0px", display: "none" }}
              >
                <div
                  className={` category-name pointer d-flex justify-content-center align-items-center fw-600 offersmb`}
                  style={{ padding: "0px" }}>
                  <img src={SaleButton} style={{ height: "38px" }} />
                </div>
              </Link>
            </div>
            {catOverflown && (
              <IoChevronForward
                className={
                  catOverflown && !catRightOverflowEnd
                    ? `mt-mb-atuo pointer`
                    : `invisible`
                }
                size="20px"
                onClick={handleForwardIconClick}
                style={{
                  transform:
                    props.lang === "en"
                      ? "unset"
                      : `rotate(
                  180deg
                  )`,
                }}
              />
            )}
          </div>
        </div>
        {isBrandsHover && (
          <div
            className="individual-category-container-desktop"
            changestate={() => setIsBrandsHover(false)}
          >
            <div className="inidividual-category-row mx-auto">
              <div
                className="individual-categories-container d-flex mx-auto"
                onMouseLeave={() => setIsBrandsHover(false)}
                style={{
                  backgroundColor: "white",
                  border: "solid 1px rgba(151, 151, 151, 0.4)",
                  padding: "0px",
                }}
              >
                <div
                  className="col-lg-3"
                  style={{
                    padding: "0px",
                    borderRight: "1px solid rgba(151, 151, 151, 0.4)",
                  }}
                >
                  <div class="brandSearchWrapper">
                    <div class="brandSearchMain">
                      <div class="SearchIcon"></div>
                      <input
                        name="brands_search"
                        type="text"
                        id="brandSearchBox"
                        placeholder={t("brand-search")}
                        value={searched_brand}
                        onChange={onSearchUserInputChanged}
                      />
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="brandsSection">
                    {filtered_brands.map((brand, i) => {
                      return (
                        <Link
                          key={brand.id}
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              "/items/products",
                            state: { brand: brand.id },
                          }}
                          style={{ color: "black", textDecoration: "none" }}
                          onClick={() => setIsBrandsHover(false)}
                        >
                          <p className={props.language == "en" ? "text-left" : "text-right"}>{props.language == "en" ? brand.name_en : brand.name_ar}</p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <div className="col-lg-9 brandsLogosSection">
                  <div className="row">
                    {filtered_brands.map((brand, i) => {
                      return (
                        <div key={brand.id} className="col-lg-2 text-center">
                          <Link
                            to={{
                              pathname:
                                "/" +
                                props.countryUrl +
                                "-" +
                                props.language +
                                "/items/products",
                              state: { brand: brand.id },
                            }}
                            onClick={() => setIsBrandsHover(false)}
                          >
                            <img
                              src={brand.image}
                              className="img-fluid"
                              style={{ height: "70px" }}
                              alt=""
                            />
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isClick && (
          <AllCategories
            data={props.categories}
            changestate={() => setIsClick(false)}
            allRef={allCategoryRef}
          />
        )}
        {isCategoryClick && (
          <IndividualCategory
            data={subCat}
            cat={selecetdCat}
            changestate={() => setIsCategoryClick(false)}
            indivRef={individualCategoryRef}
          />
        )}
        {redirectToLogin && <Redirect push to="/login" />}
        {redirectToHome && (
          <Redirect push to={"/" + props.countryUrl + "-" + props.lang + "/"} />
        )}
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    coutriesList: state.language.coutriesList,
    categories: state.header.allCategories,
    featuredCategories: state.header.featuredCategories,
    width: state.layout.screenWidth,
    lang: state.language.language,
    user: state.auth.user,
    cart_items: state.cart.cart_items,
    cart_items_length: state.cart.cart_items_length,
  };
};

const mapDispatchToProps = {
  set_country,
  set_categories,
  set_search,
  set_direction,
  set_cart_items,
  set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteHeader);
