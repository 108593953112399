require("dotenv").config();

export const domain = process.env.REACT_APP_API_DOMAIN;
const api = "api/v2";

export const apiUrl = {
  homepage: {
    offers: `${domain}/${api}/customer/offers`,
    data: `${domain}/${api}/customer/home_page?country_id=`,
    offers_data: `${domain}/${api}/customer/home_page/offers?country_id=`,
    offers_sections: `${domain}/${api}/customer/home_page/offers?country_id=`,
    banners: `${domain}/${api}/customer/home_page/banners?country_id=`,
    categories: `${domain}/${api}/customer/home_page/categories?country_id=`,
    sections: `${domain}/${api}/customer/sections/`,
    firstSection: `${domain}/${api}/customer/sections/first`,
    newArrivals: `${domain}/${api}/customer/products/homepage_section?country_id=`,
    bestSelers: `${domain}/${api}/customer/products/homepage_section?country_id=`,
    recommended_for_you: `${domain}/${api}/customer/products/homepage_section?country_id=`,
    diyProducts: `${domain}/${api}/customer/products/homepage_section?country_id=`,
    paintersTouch: `${domain}/${api}/customer/products/homepage_section?country_id=`,
    krudKutter: `${domain}/${api}/customer/products/homepage_section?country_id=`,
    home_offers_sections: `${domain}/${api}/customer/home_offers_sections?country_id=`,
    home_tabs: `${domain}/${api}/customer/home_products_tabs?country_id=`,
  },
  sections: {
    getSprayPaintsSection: `${domain}/${api}/customer/sections/spray_paints`,
    getCamping1Section: `${domain}/${api}/customer/sections/camping1`,
    getCamping2Section: `${domain}/${api}/customer/sections/camping2`,
    getDubaiEvents1Section: `${domain}/${api}/customer/sections/dubai_events_1`,
    getDubaiEvents2Section: `${domain}/${api}/customer/sections/dubai_events_2`,
    getDubaiEvents3Section: `${domain}/${api}/customer/sections/dubai_events_3`,
    getDubaiEvents4Section: `${domain}/${api}/customer/sections/dubai_events_4`,
    getRamadanEssentialsSection: `${domain}/${api}/customer/sections/ramadan_essentials`,
    getSectionProducts: `${domain}/${api}/customer/sections/`
  },
  offers: {
    offers_sections: `${domain}/${api}/customer/offers_sections`,
    offers_section_variants: `${domain}/${api}/customer/offers_sections/section_variants`,
    top_banner: `${domain}/${api}/customer/offers_banners/top_banner`,
    boxes_banner: `${domain}/${api}/customer/offers_banners/boxes_banner`
  },
  newoffers: {
    offers_sections: `${domain}/${api}/customer/new_offers_sections`,
    offers_section_variants: `${domain}/${api}/customer/new_offers_sections/section_variants`,
    top_banner: `${domain}/${api}/customer/new_offers_banners/top_banner`,
    boxes_banner: `${domain}/${api}/customer/new_offers_banners/boxes_banner`
  },
  singleItem: {
    getItemData: `${domain}/${api}/customer/`,
    getFreqData:  `${domain}/${api}/customer/products/frequently_bought`,

  },
  vendorShop: {
    getData: `${domain}/${api}/customer/stores/`,
    getMenu: `${domain}/${api}/customer/store_menu_items/`,
    getMiddleBanners: `${domain}/${api}/customer/shop_banners/`
  },
  allItems: {
    getItems: `${domain}/${api}/customer/`,
    getBrands: `${domain}/${api}/customer/brands?country_id=`,
    getCategories: `${domain}/${api}/customer/categories?country_id=`,
    getPrice: ``,
    getProductRating: ``,
    getSeller: `${domain}/${api}/customer/stores?country_id=`,
    searchItems: `${domain}/${api}/customer/`,
    getVariants: `${domain}/${api}/customer/`,
  },
  payments: {
    getPaymentData: `${domain}/${api}/customer/payment_cards`,
    deletePayment: `${domain}/${api}/customer/payment_cards`,
    setDefault: `${domain}/${api}/customer/payment_cards/`,
  },
  orders: {
    getOrders: `${domain}/${api}/customer/orders`,
    getOrderDetail: `${domain}/${api}/customer/orders`,
    cancelOrder: `${domain}/${api}/customer/orders/`,
    banners: `${domain}/${api}/customer/banners/order`,
  },
  returnItems: {
    getReturnedItems: `${domain}/${api}/customer/refunds/all`,
    create: `${domain}/${api}/customer/refunds`,
  },
  cart: {
    addToCart: `${domain}/${api}/customer/line_items`,
    moveToWishList: `${domain}/${api}/customer/wishlist_items/toggle`,
    removeItem: `${domain}/${api}/customer/line_items`,
    updateProduct: `${domain}/${api}/customer/line_items`,
    addCoupon: `${domain}/${api}/customer/coupons/calculate_discount?coupon_code=`,
    removeCoupon: `${domain}/${api}/customer/coupons/remove?user_key=`,
    clearCart: `${domain}/${api}/customer/cart/clear`,
    clearWishlist: `${domain}/${api}/customer/wishlist_items/clear`,
    getCartItems: `${domain}/${api}/customer/cart`,
    getWishList: `${domain}/${api}/customer/wishlist_items`,
    guestUser: `${domain}/${api}/customer/home_page/global_settings?country_id=`,
    getBanners: `${domain}/${api}/customer/banners/cart?country_id=`,
    updateCartReset: `${domain}/${api}/customer/cart/reset_cart_data_changed_flag?user_key=`,
    discountReset: `${domain}/${api}/customer/cart/reset_expired_coupon_removed_flag?user_key=`,
  },
  select: {
    getAddress: `${domain}/${api}/customer/addresses`,
    setDefault: `${domain}/${api}/customer/addresses/`,
    getDefault: `${domain}/${api}/customer/addresses/default`,
    addAddress: `${domain}/${api}/customer/addresses`,
    varifyAddress: `${domain}/${api}/customer/orders`,
    deletAddress: `${domain}/${api}/customer/addresses/`,
    updateAddress: `${domain}/${api}/customer/addresses/`,
  },
  phone: {
    varifyNumber: `${domain}/${api}/customer/addresses/`,
    varifyOtp: `${domain}/${api}/customer/addresses/`,
  },
  checkout: {
    pickupAddresses: `${domain}/${api}/customer/pickup_addresses`,
    shippingFee: `${domain}/${api}/customer/shippings/calculate_cost`,
    paymentCard: `${domain}/${api}/customer/cart/available_payment_methods`,
    walletPayment: `${domain}/${api}/customer/wallet?country_id=`,
    cardVar: `${domain}/${api}/customer/payment_cards`,
    placeOrder: `${domain}/${api}/customer/orders/`,
    updatedDraft: `${domain}/${api}/customer/orders/`,
  },
  wallet: {
    credit: `${domain}/${api}/customer/wallet/transactions`,
    tranfer: `${domain}/${api}/customer/wallet/transactions`,
  },
  disputes: {
    getDisputes: `${domain}/${api}/common/disputes`,
    create: `${domain}/${api}/common/disputes`,
    Dispute: `${domain}/${api}/common/disputes/`,
    createReply: `${domain}/${api}/common/disputes/`,
  },
  orderItems: {
    getItems: `${domain}/${api}/customer/orders/all_ordered_line_items`,
  },
  review: {
    addReview: `${domain}/${api}/customer/reviews`,
  },
  categories: {
    allProducts: `${domain}/${api}/customer/products/all?`,
    products: `${domain}/${api}/customer/products`,
    services: `${domain}/${api}/customer/services`,
    allCategories: `${domain}/${api}/customer/categories?country_id=`,
    getCategory: `${domain}/${api}/customer/categories/`,
    banner: `${domain}/${api}/customer/banners/mobile_all_categories?country_id=`,
  },
  profile: {
    changePass: `${domain}/${api}/customer/profile/change_password`,
    update: `${domain}/auth`,
  },
  header: {
    getBrands: `${domain}/${api}/customer/brands?country_id=`,
    getCountries: `${domain}/${api}/customer/countries`,
    allBrands: `${domain}/${api}/customer/brands?country_id=`,
    allCategories: `${domain}/${api}/customer/categories/details_with_brands?country_id=`,
    featuredCategories: `${domain}/${api}/customer/categories/featured?country_id=`,
    search: `${domain}/${api}/customer/search?country_id=`,
    recentSearches: `${domain}/${api}/customer/recent_searches`,
    deleteRecent: `${domain}/${api}/customer/recent_searches/`,
    clearHistory: `${domain}/${api}/customer/recent_searches/clear_history`,
  },
  auth: {
    resetPass: `${domain}/auth/password`,
    signout: `${domain}/auth/sign_out`,
    validateToken: `${domain}/auth/validate_token`,
  },
  platformData: {
    getPlatform: `${domain}/${api}/customer/platform`,
  },
  notify: {
    notifyMe: `${domain}/${api}/customer/products/notify_customer`,
  }
};
