import React, { useEffect, useState, useRef } from "react";
import { Button, Row } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import "../../assets/styles/product/productcard.scss";
import Logo from "../../assets/images/logo.png";

import { FaRegHeart, FaHeart } from "react-icons/fa";
import { toast } from "react-toastify";

import { FaCheckCircle, FaEye } from "react-icons/fa";
import { BsFillStarFill } from "react-icons/bs";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { apiJson } from "../../Api";
import ClipLoader from "react-spinners/ClipLoader";
import { set_home_categories } from "../../redux/actions/HomeAction";
import { useTranslation } from "react-i18next";
import { AiOutlineShoppingCart } from "react-icons/ai";
import NotifyUser from "./NotifyUser";

const en_translation = require("../../assets/locales/en/translation.json");
const ar_translation = require("../../assets/locales/ar/translation.json");

const StyledRating = withStyles({
  iconFilled: {
    color: "#ffd700",
  },
  iconHover: {
    color: "#ffd700",
  },
  iconEmpty: {
    color: "#404553",
  },
})(Rating);

const ProductCard = (props) => {
  const [adding, setAdding] = useState(false);
  const [cart_adding, setCartAdding] = useState(false);
  const [wishlisted, setWishlisted] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    new Date(new Date().setSeconds(0))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date(Date.now() + 3600 * 1000 * 24).setSeconds(0))
  );
  const [showNotifyModel, setShowNotifyModel] = useState(false);
  const [orderableId, setOrderableId] = useState(null);
  let message = "";
  const contactNumber = localStorage.getItem("contact_number") || "";
  const initialValues = {
    email: localStorage.getItem("uid") || "",
    phone: contactNumber[0] === "+" ? contactNumber.slice(1) : contactNumber,
  };
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const wishlistToggle = () => {
    if (localStorage.getItem("access_token")) {
      setAdding(true);
      apiJson.cart
        .moveToWishlist(
          {
            wishlist_item: {
              orderable_type:
                props.product.type === "products" ? "Variant" : "Service",
              orderable_id:
                props.product.type === "products"
                  ? props.product.line_item_id
                  : props.product.id,
            },
          },
          props.countryId
        )
        .then((response) => {
          setAdding(false);
          if (response.success) {
            setWishlisted(!wishlisted);
            props.home &&
              props.set_home_categories({
                positionOne: {
                  ...props.positionOne,
                  data: props.positionOne?.data?.map((data) => {
                    return data.variants
                      ? {
                          ...data,
                          variants: data.variants.map((variant) =>
                            variant.id === response.data.line_item.id
                              ? { ...variant, wishlisted: !variant.wishlisted }
                              : variant
                          ),
                        }
                      : {
                          ...data,
                          wishlisted:
                            data.id === response.data.line_item.id
                              ? !data.wishlisted
                              : data.wishlisted,
                        };
                  }),
                },
                positionTwo: {
                  ...props.positionTwo,
                  data: props.positionTwo?.data?.map((data) => {
                    return data.variants
                      ? {
                          ...data,
                          variants: data.variants.map((variant) =>
                            variant.id === response.data.line_item.id
                              ? { ...variant, wishlisted: !variant.wishlisted }
                              : variant
                          ),
                        }
                      : {
                          ...data,
                          wishlisted:
                            data.id === response.data.line_item.id
                              ? !data.wishlisted
                              : data.wishlisted,
                        };
                  }),
                },
                positionThree: {
                  ...props.positionThree,
                  data: props.positionThree?.data?.map((data) => {
                    return data.variants
                      ? {
                          ...data,
                          variants: data.variants.map((variant) =>
                            variant.id === response.data.line_item.id
                              ? { ...variant, wishlisted: !variant.wishlisted }
                              : variant
                          ),
                        }
                      : {
                          ...data,
                          wishlisted:
                            data.id === response.data.line_item.id
                              ? !data.wishlisted
                              : data.wishlisted,
                        };
                  }),
                },
                positionFour: {
                  ...props.positionFour,
                  data: props.positionFour?.data?.map((data) => {
                    return data.variants
                      ? {
                          ...data,
                          variants: data.variants.map((variant) =>
                            variant.id === response.data.line_item.id
                              ? { ...variant, wishlisted: !variant.wishlisted }
                              : variant
                          ),
                        }
                      : {
                          ...data,
                          wishlisted:
                            data.id === response.data.line_item.id
                              ? !data.wishlisted
                              : data.wishlisted,
                        };
                  }),
                },
                positionFive: {
                  ...props.positionFive,
                  data: props.positionFive?.data?.map((data) => {
                    return data.variants
                      ? {
                          ...data,
                          variants: data.variants.map((variant) =>
                            variant.id === response.data.line_item.id
                              ? { ...variant, wishlisted: !variant.wishlisted }
                              : variant
                          ),
                        }
                      : {
                          ...data,
                          wishlisted:
                            data.id === response.data.line_item.id
                              ? !data.wishlisted
                              : data.wishlisted,
                        };
                  }),
                },
                sections: props.sections.map((section) => {
                  return {
                    ...section,
                    data: section.data.map((data) => {
                      return data.variants
                        ? {
                            ...data,
                            variants: data.variants.map((variant) =>
                              variant.id === response.data.line_item.id
                                ? {
                                    ...variant,
                                    wishlisted: !variant.wishlisted,
                                  }
                                : variant
                            ),
                          }
                        : {
                            ...data,
                            wishlisted:
                              data.id === response.data.line_item.id
                                ? !data.wishlisted
                                : data.wishlisted,
                          };
                    }),
                  };
                }),
              });
          } else if (!response.status) {
            message =
              props.language === "ar" ? "هناك خطأ ما" : t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                ? response.errors
                : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    } else {
      message =
        props.language === "en"
          ? "Please login first"
          : "الرجاء تسجيل الدخول أولا";
      showError();
      setRedirect(true);
    }
  };

  useEffect(
    () => setWishlisted(props.product.wishlisted),
    [props.product.wishlisted]
  );

  const addToCart = () => {
    // console.log(props.product);
    // let variant = props.product?.variants.filter(variant => variant.sku == selected_sku)[0];

    if (props.product?.product_type == "wholesale") {
      // if (props.product.qty3range_to) {
      //   if (parseInt(quantity) > parseInt(props.product.qty3range_to)) {
      //     message = "This product is not available in this quantity. Maximum available stock is " + props.product.qty3range_to + ".";
      //     showError();
      //   }
      //   else {
      //     addToCartApiMethod(props.product);
      //   }
      // }
      // else if (props.product.qty2range_to) {
      //   if (parseInt(quantity) > parseInt(props.product.qty2range_to)) {
      //     message = "This product is not available in this quantity. Maximum available stock is " + props.product.qty2range_to + ".";
      //     showError();
      //   }
      //   else {
      //     addToCartApiMethod(props.product);
      //   }
      // }
      // else {
      //   if (parseInt(quantity) > parseInt(props.product.qty1range_to)) {
      //     message = "This product is not available in this quantity. Maximum available stock is " + props.product.qty1range_to + ".";
      //     showError();
      //   }
      //   else {
      //     addToCartApiMethod(props.product);
      //   }
      // }
    } else {
      addToCartApiMethod(props.product);
    }
  };

  const addToCartApiMethod = (variant) => {
    setCartAdding(true);

    apiJson.cart
      .addToCart(
        variant.product_type == "wholesale"
          ? {
              line_item: {
                quantity: 1,
                orderable_type: 'Variant',
                orderable_id: variant.line_item_id,
                product_type: 'wholesale',
              },
            }
          : variant.type === "products"
          ? {
              line_item: {
                quantity: 1,
                orderable_type: "Variant",
                orderable_id: variant.line_item_id,
              },
            }
          : {
              line_item: {
                orderable_type: "Service",
                orderable_id: variant.id,
                start_at: new Date(startDate).toString(),
                end_at: new Date(endDate).toString(),
              },
            },
        props.countryId
      )
      .then((response) => {
        setCartAdding(false);
        if (response.success) {
          if (
            localStorage.getItem("access_token") ||
            localStorage.getItem("user_key") === response.data.user_key
          ) {
            props.setShowCartSidebar(true);
          } else {
            localStorage.setItem("user_key", response.data.user_key);
            props.setShowCartSidebar(true);
          }
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
              ? response.errors
              : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  };

  return (
    <div
      className="product-card-container-desktop mb-2"
      // style={{
      //   height: props.product.is_inquiry ? "fit-content" : "auto"
      // }}
    >
      <Link
        style={{ textDecoration: "none" }}
        to={{
          pathname:
            "/" +
            props.countryUrl +
            "-" +
            props.language +
            `/${props.product.category_en?.toLowerCase().replace(/ /g, "-")}/${
              props.product.slug
            }`,
          state: {
            id: props.product.id,
            type: props.product.type,
            sku: props.product.sku,
          },
          search:
            props.product.type == "products" ? "?sku=" + props.product.sku : "",
        }}
      >
        <Row className={`product-badge-row mx-auto `}>
          {props.width !== "sm" && (
            <div
              className={`badge-container ${
                props.product.badge ? "" : "invisible w-0"
              }`}
            ></div>
          )}
          {props.product.is_inquiry ? (
            <></>
          ) : props.product.price_before_discount !=
            props.product.price_after_discount ? (
            <div
              className={
                props.language == "en" ? "discount-box" : "discount-box-ar"
              }
            >
              <p
                style={{ color: "white", fontSize: "12px" }}
                className="font-weight-bold m-0"
              >
                &nbsp;
                {Math.round(
                  ((props.product.price_before_discount -
                    props.product.price_after_discount) /
                    props.product.price_before_discount) *
                    100
                )}
                % OFF
              </p>
            </div>
          ) : (
            <></>
          )}
        </Row>
        <div
          onClick={(e) => {
            e.preventDefault();
            wishlistToggle();
          }}
          className={
            "pointer eye-button-container" +
            (props.language == "ar" ? " eye-button-container-ar" : "")
          }
        >
          <div
            className="pointer like-button-container"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {adding ? (
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <ClipLoader color="#000" loading size={16} />
              </div>
            ) : wishlisted ? (
              <FaHeart color="#d4982b" size={16} />
            ) : (
              <FaRegHeart color="grey" size={16} />
            )}
          </div>

          {/* {adding ? (
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <ClipLoader color="#000" loading size={20} />
              </div>
            ) : wishlisted ? (
              <FaHeart color="#d4982b" size={24} />
            ) : (
              <FaRegHeart color="grey" size={24} />
            )} */}
        </div>
        {props.product.type == "products" ? (
          <div
            className={
              "pointer eye-button-container2" +
              (props.language == "ar" ? " eye-button-container-ar" : "")
            }
            onClick={(e) => {
              e.preventDefault();
              props.onQuickViewClicked(
                props.product,
                props.product.variants?.length > 0
                  ? props.product.variants[0].sku
                  : props.product.sku
              );
            }}
          >
            <FaEye color="#d4982b" size={16} />
          </div>
        ) : (
          <></>
        )}

        <Row className="product-image-row mx-auto">
          <img className="img mx-auto" src={props.product.img} alt="product" />
        </Row>
        <Row className="product-description-row mx-auto">
          {props.product.is_inquiry ? (
            <></>
          ) : (
            <div className="coupon-row">
              {props.product.coupon ? (
                new Date(props.product.coupon.expiry) >= new Date() ? (
                  <div className="coupon-box mb-1">
                    <p className="px-2 my-0">
                      Coupon: {props.product.coupon.code}
                    </p>
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>
          )}
          {props.product.promo ? (
            <div
              className={`product-promo-container mx-auto ${
                props.product.promo ? '' : 'invisible'
              }`}
            >
              <div className="product-promo">{props.product.promo}</div>
            </div>
          ) : (
            <></>
          )}

          <div className="product-description-container">
            <div className="product-description">
              {props.product.description}
            </div>
          </div>
          <div className="product-description-container">
            {/* <div className="product-description"> */}
            {props.product.variant_name}
            {/* </div> */}
          </div>
          {props.product.is_inquiry ? (
            <></>
          ) : props.product.product_type == "wholesale" ? (
            <div className="product-price-container">
              <div className="regular-price">
                <span className="currency fw-700">
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR"
                    : props.country.toLowerCase() === "united arab emirates"
                    ? "AED"
                    : "EGP"}{" "}
                </span>
                <span className="value">{props.product.start_price}</span>
                <span className="value"> - </span>
                <span className="currency fw-700">
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR"
                    : props.country.toLowerCase() === "united arab emirates"
                    ? "AED"
                    : "EGP"}{" "}
                </span>
                <span className="value">{props.product.end_price}</span>
              </div>
            </div>
          ) : (
            <div className="product-price-container">
              <div
                className={
                  props.product.price_after_discount ==
                  props.product.price_before_discount
                    ? "regular-price"
                    : "price-after-discount"
                }
              >
                <span className="currency fw-700">
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR"
                    : props.country.toLowerCase() === "united arab emirates"
                    ? "AED"
                    : "EGP"}{" "}
                </span>
                <span className="value">
                  {props.product.price_after_discount}
                  {props.product?.type == "services" && " / Hour"}
                </span>
              </div>
              <div
                className={`${
                  props.product.price_before_discount -
                    props.product.price_after_discount >
                  0
                    ? ""
                    : "invisible "
                }price-before-discount text-uppercase`}
              >
                <span className="currency">
                  {props.country.toLowerCase() === "saudi arabia"
                    ? "SAR"
                    : props.country.toLowerCase() === "united arab emirates"
                    ? "AED"
                    : "EGP"}{" "}
                </span>
                <span className="value">
                  {props.product.price_before_discount}
                  {props.product?.type == "services" && " / Hour"}
                </span>
              </div>
            </div>
          )}
        </Row>
        <Row className="product-rating-row mx-auto">
          <div className="product-rating-container d-flex align-items-center">
            <StyledRating
              name="customized-empty"
              defaultValue={2}
              precision={0.5}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              size="small"
              readOnly
              value={props.product.rating}
            />
            <div className="product-rating-count">{`(${props.product.count})`}</div>
          </div>
        </Row>
        <Row className="seller-info-row mx-auto">
          <div className="seller-info-container">
            <div className="seller-logo">
              <img
                src={props.product.storeLogo ? props.product.storeLogo : Logo}
                alt="logo"
                style={{
                  width: "auto",
                  height: "25px",
                  maxWidth: "85px",
                  maxHeight: "25px",
                }}
              />
            </div>
            <div className="seller-verified">
              <FaCheckCircle />
            </div>

            <div className="d-flex flex-direction-row align-items-center rating-mobile-cont">
              <BsFillStarFill size="12" color="rgb(245, 165, 35)" />
              <span className="rating-mobile-value">
                {props.product.rating}
              </span>
              <span className="rating-mobile-count">{`(${props.product.count})`}</span>
            </div>
          </div>
        </Row>
      </Link>
      <Row className="m-0 quick-view-row">
        {props.product?.type == "products" ? (
          props.product?.product_type == "wholesale" ? (
            props.product?.is_inquiry ? (
              <a
                className="inquire-now-btn"
                href={encodeURI(
                  `//api.whatsapp.com/send?phone=971543064845&text=Hi, I would like to inquire about the product. I am interested in : ${
                    props.product["description_" + props.language]
                  }. Check here: ${
                    process.env.REACT_APP_WEBSITE_URL +
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    "/" +
                    props.product.category_en
                      ?.toLowerCase()
                      .replace(/ /g, "-") +
                    "/" +
                    props.product?.slug
                  }?sku=${props.product?.sku}`
                )}
                target="_blank"
              >
                {t("item-detail.get-inquiry")}
              </a>
            ) : (
              <></>
            )
          ) : props.product?.is_inquiry ? (
            <a
              className="inquire-now-btn"
              href={encodeURI(
                `//api.whatsapp.com/send?phone=971543064845&text=Hi, I would like to inquire about the product. I am interested in : ${
                  props.product['description_' + props.language]
                }. Check here: ${
                  process.env.REACT_APP_WEBSITE_URL +
                  '/' +
                  props.countryUrl +
                  '-' +
                  props.language +
                  '/' +
                  props.product.category_en?.toLowerCase().replace(/ /g, '-') +
                  '/' +
                  props.product?.slug
                }?sku=${props.product?.sku}`
              )}
              target="_blank"
            >
              {t("item-detail.get-inquiry")}
            </a>
          ) : props.product?.quantity > 0 ? (
            <Button className="quick-view-btn" onClick={addToCart}>
              <AiOutlineShoppingCart size={20} />
              &nbsp;
              {props.language == "en"
                ? en_translation["item-detail"]["shortcut-add-to-cart"]
                : ar_translation["item-detail"]["shortcut-add-to-cart"]}
            </Button>
          ) : (
            <div style={{ display: "flex", flexDirection: "row", gap: 15 }}>
              <span className="out-of-stock-btn">
                {t("item-detail.sold-out")}
              </span>
              <Button
                className="notify-me-btn"
                onClick={() => {
                  setShowNotifyModel(true);
                  setOrderableId(props.product.line_item_id);
                }}
              >
                {t("item-detail.notify-me")}
              </Button>
            </div>
          )
        ) : (
          <></>
        )}
      </Row>
      <NotifyUser
        setShowNotifyModel={setShowNotifyModel}
        showNotifyModel={showNotifyModel}
        id={orderableId}
        language={props.language}
      />

      {redirect && <Redirect push to="/login" />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    width: state.layout.screenWidth,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    bestSellers: state.home.bestSellers,
    newArrival: state.home.newArrival,
    countryId: state.language.countryId,
    sections: state.home.sections,

    positionOne: state.home.positionOne,
    positionTwo: state.home.positionTwo,
    positionThree: state.home.positionThree,
    positionFour: state.home.positionFour,
    positionFive: state.home.positionFive,
  };
};

const mapDispatchToProps = { set_home_categories };
export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
